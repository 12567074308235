import React, { useContext, useEffect, useState } from 'react';
import PaymentBox from '../payment-box/payment-box.jsx'
import CustomMultiSelect from '../custom-multi-select/custom-multi-select.jsx';
import DateRangePicker from '../date-range-picker/date-range-picker.jsx';
import { ApiContext } from '../../context/apiContext';
import { formatCurrency } from '../../utilities/utility';
import { toast } from 'react-toastify';
import { 
    Container,
    CanvasSmall,
    TableContainer, 
    TableRow, 
    TableHeaderCell,
    TableCell, 
    StatusBadge, 
    PaymentSubtableContainer,
    PaymentSubtableRow,
    IconButton, 
    IconDiv
} from './payment-deal-tab.styles';

import { ReactComponent as Edit } from '../../assets/edit.svg'
import { ReactComponent as Reminder } from '../../assets/reminder.svg'
import { ReactComponent as ReminderSent } from '../../assets/reminder-sent.svg'
import { ReactComponent as View } from '../../assets/view.svg'
import { ReactComponent as ArrowUnsort } from '../../assets/Arrow - Unsort.svg';
import { ReactComponent as ArrowSort }  from '../../assets/Arrow - Sort.svg'; // Replace with actual path


const PaymentDealTab = () => {
    const [dealData, setdealData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [focusDeal, setFocusDeal] = useState({});
    const [focusPayment, setFocusPayment] = useState({
        rentType: '',
        rentValue: {}
    })
    const [sortOrder, setSortOrder] = useState(null);
    const api = useContext(ApiContext);

    // Filters state
    const [dealFilter, setDealFilter] = useState([]);
    const [agentFilter, setAgentFilter] = useState([]);
    const [dateRangeFilter, setDateRangeFilter] = useState({ from: '', to: '' });
    const [moveinDateRangeFilter, setMoveinDateRangeFilter] = useState({ from: '', to: '' });
    const [statusFilter, setStatusFilter] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await api.get('/api/payment/all');
                setdealData(data); 
                setFilteredData(data);
            } catch (error) {
                toast.error(`There was an error loading the data: ${error.message}`);
            } 
        }
        fetchData();
    },[])

    // Update filtered data when any filter changes
    useEffect(() => {
        let updatedData = dealData;

        if (dealFilter.length > 0) {
            updatedData = updatedData.filter(deal => dealFilter.includes(deal.property.address));
        }
        if (agentFilter.length > 0) {
            updatedData = updatedData.filter(deal => agentFilter.includes(deal.agent.name));
        }
        if (dateRangeFilter.from && dateRangeFilter.to) {
            updatedData = updatedData.map(deal => ({
                ...deal,
                dealMoveinDeposits: Object.fromEntries(
                    Object.entries(deal.dealMoveinDeposits).filter(([_, payment]) =>
                        new Date(payment.dueDate) <= new Date(dateRangeFilter.to) &&
                        new Date(payment.dueDate) >= new Date(dateRangeFilter.from)
                    )
                )
            })).filter(deal => Object.keys(deal.dealMoveinDeposits).length > 0);
        }
        if (moveinDateRangeFilter.from && moveinDateRangeFilter.to) {
            updatedData = updatedData.filter(deal =>
                new Date(deal.property.moveIn) >= new Date(moveinDateRangeFilter.from) &&
                new Date(deal.property.moveIn) <= new Date(moveinDateRangeFilter.to)
            );
        }
        if (statusFilter.length > 0) {
            updatedData = updatedData.map(deal => ({
                ...deal,
                dealMoveinDeposits: Object.fromEntries(
                    Object.entries(deal.dealMoveinDeposits).filter(([_, payment]) =>
                        statusFilter.includes(payment.status)
                    )
                )
            })).filter(deal => Object.keys(deal.dealMoveinDeposits).length > 0);
        }

        // Sort by "Total Due" based on the selected order
        if(sortOrder){
            updatedData.sort((a, b) => {
                const totalDueA = Object.values(a.dealMoveinDeposits)
                    .filter(deposit => deposit.status === "Pending")
                    .reduce((total, deposit) => total + deposit.amount, 0);
                const totalDueB = Object.values(b.dealMoveinDeposits)
                    .filter(deposit => deposit.status === "Pending")
                    .reduce((total, deposit) => total + deposit.amount, 0);
    
                return sortOrder === 'asc' ? totalDueA - totalDueB : totalDueB - totalDueA;
            })
        } else {
            updatedData.sort((a, b) => new Date(a.acceptanceDate) - new Date(b.acceptanceDate));
        }
    
        setFilteredData(updatedData);
    }, [dealFilter, agentFilter, dateRangeFilter, statusFilter, dealData, moveinDateRangeFilter, sortOrder]);

    // Handle filters
    const handleDealFilterChange = (selectedOptions) => {
        setDealFilter(selectedOptions);
    };

    
    const handleAgentFilterChange = (selectedOptions) => {
        setAgentFilter(selectedOptions);
    };

    const handleStatusFilterChange = (selectedOptions) => {
        setStatusFilter(selectedOptions);
    };

    // Toggle sorting logic
    const toggleSortOrder = () => {
        setSortOrder(prevOrder => {
            if(prevOrder === null) return 'asc'
            else if(prevOrder === 'asc') return 'desc'
            else return null
        })
    };

    const renderSortIcon = () => {
        if (sortOrder === null) {
            return <ArrowUnsort />;
        }
        return sortOrder === 'asc' ? (
            <ArrowSort style={{ transform: 'scaleY(-1)' }} />
        ) : (
            <ArrowSort />
        );
    };

    const dealOptions = [...new Set(dealData.map(deal => ({ value: deal.property.address, label: deal.property.address })))];
    const agentOptions = [...new Set(dealData.map(deal => ({ value: deal.agent.name, label: deal.agent.name })))];
    const statusOptions = [
        { value: 'Pending', label: 'Pending' },
        { value: 'Completed', label: 'Completed' }
    ];


    const sendReminder = async (dealId, rentType) => {
        const payload = {
            dealId: dealId,
            rentType: rentType
        }
        try {
            const data = await api.post('/api/deal/payment_reminder', payload);
            toast.info('Reminder sent to the agent and applicants')
        }catch (error){
            toast.error(`Something didnt work like it was supposed to: ${error}`);
        }
    }

    const resetFocus = () => {
        setFocusDeal({})
        setFocusPayment({
            dealId: null,
            rentType: '',
            rentValue: {}
        })
    }


    const handleSelectedPayment = ( deal, rentType, rentValue) => {
        if(focusPayment?.rentType === rentType && focusDeal?.dealId === deal.dealId){
            resetFocus();
        }else {
            setFocusDeal(deal)
            setFocusPayment({
                rentType: rentType,
                rentValue: rentValue
            })
        }
    }

    const updatePayment = (updateData, depositType) => {
        setdealData(currentDeals => currentDeals.map(currentDeal => currentDeal.dealId === focusDeal.dealId ?
            {
                ...currentDeal, 
                dealMoveinDeposits: {
                    ...currentDeal.dealMoveinDeposits,
                    [depositType]: updateData[depositType]
                }
            }
            :
            currentDeal
        ))
        
    }

    const hasFocusPayment = focusPayment?.rentType !== '';

    
    return (
        <Container>
            <TableContainer showSmallCanvas={hasFocusPayment}>
                <TableRow as="header">
                    <TableHeaderCell>
                        <CustomMultiSelect
                            options={dealOptions}
                            selectedOptions={dealFilter}
                            onChange={handleDealFilterChange}
                            placeholder="Deal"
                        />
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <DateRangePicker
                            selectedDateRange={moveinDateRangeFilter}
                            onChange={setMoveinDateRangeFilter}
                            placeholder="Move In"
                        />
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <CustomMultiSelect
                            options={agentOptions}
                            selectedOptions={agentFilter}
                            onChange={handleAgentFilterChange}
                            placeholder="Agent"
                        />
                    </TableHeaderCell>
                    <TableHeaderCell onClick={toggleSortOrder} style={{ cursor: 'pointer' }}>
                        Total Due {renderSortIcon()}
                    </TableHeaderCell>
                    <TableHeaderCell>Type</TableHeaderCell>
                    <TableHeaderCell>
                        <DateRangePicker
                            selectedDateRange={dateRangeFilter}
                            onChange={setDateRangeFilter}
                            placeholder="Due Date"
                        />
                    </TableHeaderCell>
                    <TableHeaderCell>Amount</TableHeaderCell>
                    <TableHeaderCell>
                        <CustomMultiSelect
                            options={statusOptions}
                            selectedOptions={statusFilter}
                            onChange={handleStatusFilterChange}
                            placeholder="Status"
                        />
                    </TableHeaderCell>
                    <TableHeaderCell>Actions</TableHeaderCell>
                </TableRow>
                {
                    filteredData.length > 0  && filteredData?.map((deal, dealIndex) => (
                        <React.Fragment key={dealIndex}>
                            <TableRow>
                                <TableCell style={{gap: '5px', display:'flex', flexDirection:'column'}}>
                                    <div style ={{textWrap:'auto'}}>{deal.property.address}</div>
                                    <div style={{fontSize:'11px', color: 'gray'}}>Rent: {formatCurrency(deal.property.price)}</div>
                                </TableCell>
                                <TableCell>
                                    {deal.property.moveIn}
                                </TableCell>
                                <TableCell style={{gap: '5px', display:'flex', flexDirection:'column'}}>
                                    <div>{deal.agent.name}</div>
                                    <div style={{fontSize:'11px', color: 'gray'}}>{deal.agent.brokerage}</div>
                                </TableCell>
                                <TableCell>
                                    <div>
                                        {
                                            formatCurrency(Object.values(deal.dealMoveinDeposits)
                                            .filter(deposit => deposit.status === "Pending")
                                            .reduce((total, deposit) => total + deposit.amount, 0))
                                        }
                                    </div>
                                </TableCell>
                                <TableCell colSpan={5}> {/* The payment details will go below */}
                                    <PaymentSubtableContainer>
                                    {
                                        Object.entries(deal.dealMoveinDeposits).map(([type, payment], paymentIndex) => (
                                            <PaymentSubtableRow key={paymentIndex} highlight={new Date(payment.dueDate) < new Date() && payment.status !== "Completed"}>
                                                <div>{type}</div>
                                                <div>{payment.dueDate}</div>
                                                <div>{formatCurrency(payment.amount)}</div>
                                                <div>
                                                    <StatusBadge status = {payment.status}>
                                                        {payment.status}
                                                    </StatusBadge>
                                                </div>
                                                <div >
                                                    <IconDiv>
                                                        {
                                                            payment.status !== "Completed"?
                                                            <>
                                                                <IconButton 
                                                                    reminder = {payment.reminder} 
                                                                    onClick = {() => sendReminder(deal.dealId, type)}
                                                                >
                                                                    {
                                                                        payment.reminder ?
                                                                        <ReminderSent/>:<Reminder/>
                                                                    }
                                                                </IconButton>
                                                                <IconButton 
                                                                    selected = {focusPayment?.rentType === type && focusDeal.dealId === deal.dealId}  
                                                                    onClick = {() => handleSelectedPayment(deal, type, payment)}
                                                                >
                                                                    <Edit stroke = {focusPayment?.type === type && '#064274'}/>
                                                                </IconButton>
                                                            </>
                                                            :
                                                            <IconButton 
                                                                selected = {focusPayment?.rentType === type && focusDeal.dealId === deal.dealId} 
                                                                onClick = {() => handleSelectedPayment(deal, type, payment)}
                                                            >
                                                                <View stroke = {focusPayment?.rentType === type && '#064274'}/>
                                                            </IconButton>
                                                        }
                                                    </IconDiv>
                                                </div>
                                            </PaymentSubtableRow>
                                        ))
                                    }
                                    </PaymentSubtableContainer>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))
                }
            </TableContainer>
            <CanvasSmall showSmallCanvas={hasFocusPayment}>
                <PaymentBox
                    showPayment = {true}
                    focusPayment = {focusPayment}
                    focusDeal = {focusDeal}
                    dealId = {focusDeal.dealId}
                    updateDeal = {updatePayment}
                    onClose = {() => resetFocus()}
                />
            </CanvasSmall>
        </Container>
    );

}

export default PaymentDealTab