import styled from "styled-components";

export const PropertyImg = styled.img`
    width: 75px;
    height: 75px;
    border-radius: 7px;
    object-fit: cover;
`;

export const PropertyPrice = styled.div`
    font-size: 12px;
    color: ${props => props.priceUp ? '#1CCB00': props.priceDown ? '#FF0000':'var(--Grey, #747474)'};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 3px;

    @media screen and (max-width: 600px){
        font-size: 12px;
    }
`;

export const PropertyAddress = styled.h2`
    font-size: 16px;
    color: #323232;
    margin: 0;
    font-weight: 500;
`;

// Extend FlexDiv for different use cases
export const FlexDivGapAlign = styled.div`
    gap: 15px;
    align-items: center;
    display: flex;
    align-items: center;
`;

export const FlexDivColumn = styled.div`
    display: flex;
    gap: 3px;
    flex-direction: column;
    flex: 1;
`;

export const FlexDivGapLarge = styled.div`
    gap: 20px;
    display: flex;
    align-items: center;
`;