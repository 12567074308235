import React, { useState, useEffect } from "react";
import {toast} from 'react-toastify'
import{
    ModalOverlay, ModalContainer, ModalHeader, ModalTitle, CloseButton, ModalBody, FormGroup, Label, Input, ModalFooter,
    ConfirmButton, CancelButton, NameText
} from './edit-application-modal.styles'

const EditApplicantsModal = ({ isOpen, toggle, type, applicants, updateApplicants, applicationId }) => {
    const [emailUpdates, setEmailUpdates] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // State to manage loading state

    const handleInputChange = (id, value) => {
        setEmailUpdates(emailUpdates.map(app => app.id === id ? { ...app, email: value } : app));
    };

    useEffect(() => {
        if (isOpen) {
            setEmailUpdates(applicants.map(applicant => ({ 
                id: type==="applicants"? applicant.id: applicant.guarantorId, 
                email: applicant.email, 
                name: applicant.name 
            })));
        }
    }, [isOpen, applicants, type]);
  
    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const payload = {
                applicationId: applicationId,
                emailUpdates: emailUpdates,
                type: type
            }

            const response = await fetch('/api/application/edit', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error('Failed to update applicants');
            }

            updateApplicants(emailUpdates);
            toggle();
            toast.success('Applicants updated successfully!');
        } catch (error) {
            toast.error('Error updating applicants: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };
  
    if (!isOpen) return null;

    // console.log(applicants)
  
    return (
        <ModalOverlay onClick={toggle}>
            <ModalContainer onClick={e => e.stopPropagation()}>
                <ModalHeader>
                    <ModalTitle>Edit {type === 'applicants' ? 'Applicants' : 'Guarantors'}</ModalTitle>
                    {/* <CloseButton onClick={toggle}>&times;</CloseButton> */}
                </ModalHeader>
                <ModalBody>
                    {
                        emailUpdates.map(({ id, email, name }) => (
                            <FormGroup key={id}>
                                <NameText>{name}</NameText>
                                <Input
                                    type="email"
                                    id={`email-${id}`}
                                    value={email}
                                    onChange={(e) => handleInputChange(id, e.target.value)}
                                />
                            </FormGroup>
                        ))
                    }
                </ModalBody>
                <ModalFooter>
                    <CancelButton onClick={toggle}  disabled={isLoading}>Cancel</CancelButton>
                    <ConfirmButton onClick={handleSubmit} disabled={isLoading}>Save</ConfirmButton>
                </ModalFooter>
            </ModalContainer>
        </ModalOverlay>
    );
  };
  
  export default EditApplicantsModal;