import React, { useState, useContext } from "react";
import ReactDOM from 'react-dom';
import { ApiContext } from "../../context/apiContext";
import FormInput from "../form-input/form-input";
import { UserContext } from "../../context/userContext";
import { toast } from "react-toastify";
import {
    ModalBackground,
    ModalContent,
    WrapDiv,
    Heading,
    ButtonDiv,
    PreviousButton,
    NextButton
} from './update-rent-modal.styles'

const UpdateRentModal = ({focusApplication, updateApplication, onClose, setFocusApplication = null, agentId = null}) => {
    const api = useContext(ApiContext);
    const [errors, setErrors] = useState({});
    const [baseRent, setBaseRent] = useState(focusApplication?.offerPrice);
    const { user } = useContext(UserContext);

    const validateInputs = () => {
        let newErrors = {};
        if(baseRent === undefined || baseRent === null || baseRent === '') newErrors.type = "Offer amount is required";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const handleSubmit = async () => {
        if(validateInputs()){
            try {
                const data = user?.id ? 
                (await api.put(`/api/application/rent_offer `, { applicationId: focusApplication.id, offerPrice: baseRent }))
                :
                await (await fetch('/api/application/rent_offer_agent', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json', // Ensure server understands JSON
                        // Add other headers if needed (like Authorization tokens)
                    },
                    body: JSON.stringify({
                        agentId: agentId,
                        applicationId: focusApplication.id, 
                        offerPrice: baseRent
                    })
                } )).json();
                    // console.log(data)
                if(focusApplication && setFocusApplication){
                    setFocusApplication(prev => ({...prev, data}))
                }
                // console.log('data', data, focusApplication)
                updateApplication(data);
                toast.success(`Deposit updated`)
                onClose();
            } catch (error) {
                toast.error(`There was an error during the update: ${error.message}`);
            }
        }
    }

    return ReactDOM.createPortal(
        <ModalBackground onClick={onClose}>
            <ModalContent onClick={ (event) => event.stopPropagation()}>
                <WrapDiv>
                    <Heading>Update Base Rent</Heading>
                    <FormInput
                        type="number"
                        placeholder="Rent Amount"
                        // label = "Update Base Rent"
                        defaultValue={baseRent}
                        handleChange={value => setBaseRent(value)}
                        isRequired={true}
                        error = {errors.type}
                        // flexBasis={true}
                    />
                </WrapDiv>
                <ButtonDiv>
                    <PreviousButton style ={{width:'150px'}} onClick={onClose}>Close</PreviousButton>
                    <NextButton style ={{width:'150px'}}  onClick={handleSubmit}>Update Payment</NextButton>
                </ButtonDiv>
            </ModalContent>
        </ModalBackground>,
        document.getElementById('modal-root')
    );
}

export default UpdateRentModal