import React, {useState, useEffect, useContext} from "react";
import CommissionBox from "../commission-box/commission-box";
import CustomMultiSelect from '../custom-multi-select/custom-multi-select.jsx';
// import data from './data.json'
import { toast } from "react-toastify";
import { ApiContext } from '../../context/apiContext';
import { formatCurrency } from "../../utilities/utility";
import { Container, TableContainer, TableRow, TableHeaderCell, TableCell, StatusBadge, CanvasSmall} from "./payment-agent-tab.styles";

const PaymentAgentTab = () => {
    const [focusAgent, setFocusAgent] = useState()
    const [agentData, setAgentData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const api = useContext(ApiContext);


    const [agentFilter, setAgentFilter] = useState([]);
    const [statusFilter, setStatusFilter] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await api.get('/api/payment/commission/all');
                setAgentData(data.filter(agent => agent.uninvoicedDeals.length > 0 || agent.invoices.length > 0));
                setFilteredData(data.filter(agent => agent.uninvoicedDeals.length > 0 || agent.invoices.length > 0));
            } catch (error) {
                toast.error(`There was an error loading the data: ${error.message}`);
            } 
        }
        fetchData();
    },[])

    // Update filtered data when any filter changes
    useEffect(() => {
        let updatedData = agentData;

        if (agentFilter.length > 0) {
            updatedData = updatedData.filter(agent => agentFilter.includes(agent.agentName));
        }
        if (statusFilter.length > 0) {
            updatedData = updatedData.filter(agent => {
                const commissionStatus = getCommissionStatus(agent);
                return statusFilter.includes(commissionStatus);
            });
        }

        setFilteredData(updatedData);
    }, [agentFilter, statusFilter, agentData]);

    const agentOptions = [...new Set(agentData.map(agent => ({ value: agent.agentName, label: agent.agentName })))];
    const statusOptions = [
        { value: 'Pending', label: 'Pending' },
        { value: 'Completed', label: 'Completed' },
        { value: 'Partially Complete', label: "Partially Complete"}
    ];

    const handleAgentFilterChange = (selectedOptions) => {
        setAgentFilter(selectedOptions);
    };

    const handleStatusFilterChange = (selectedOptions) => {
        setStatusFilter(selectedOptions);
    };

    const updateAgent = (updateData) => {
        setAgentData(prev => prev.map(agent => agent.agentId === updateData.agentId ?
            {
                ...agent,
                invoices: agent.invoices.map(invoice => invoice.invoiceId === updateData.invoiceId ?
                    {
                        ...invoice,
                        status: updateData.status,
                        checkFile: updateData.checkFile,
                        deals: invoice.deals.map(deal => ({
                            ...deal,
                            status: updateData.status
                        }))
                    }
                    :
                    invoice
                )
            }
            :
            agent
        ))
        setFocusAgent({
            ...focusAgent,
            invoices: focusAgent.invoices.map(invoice => invoice.invoiceId === updateData.invoiceId ?
                {
                    ...invoice,
                    status: updateData.status,
                    checkFile: updateData.checkFile,
                    deals: invoice.deals.map(deal => ({
                        ...deal,
                        status: updateData.status
                    }))
                }
                :
                invoice
            )
        })
    }

    const getCommissionStatus = (agent) => {
        let hasPending = false;
        let hasCompleted = false;

        // Check uninvoiced deals
        for (let deal of agent.uninvoicedDeals) {
            if (deal.status === "Pending") {
                hasPending = true;
            } else if (deal.status === "Completed") {
                hasCompleted = true;
            }
        }
        console.log(hasCompleted, hasPending)
        // Check invoices and their deals
        for (let invoice of agent.invoices) {
            if (invoice.status === "Pending") {
                hasPending = true;
            } else if (invoice.status === "Completed") {
                hasCompleted = true;
            }

        }
        console.log(hasCompleted, hasPending)
        if (hasPending && hasCompleted) {
            return "Partially Complete";
        } else if (hasPending) {
            return "Pending";
        } else if (hasCompleted) {
            return "Completed";
        } else {
            console.log("its unknown")
            return "Unknown"; // fallback if no status found
        }
    };

    return (
        <Container>
            <TableContainer showSmallCanvas = {focusAgent}>
                <TableRow as="header">
                    <TableHeaderCell>
                        <CustomMultiSelect
                            options={agentOptions}
                            selectedOptions={agentFilter}
                            onChange={handleAgentFilterChange}
                            placeholder="Agent"
                        />
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <CustomMultiSelect
                            options={statusOptions}
                            selectedOptions={statusFilter}
                            onChange={handleStatusFilterChange}
                            placeholder="Comission Status"
                        />
                    </TableHeaderCell>
                    <TableHeaderCell>Outstanding Fee</TableHeaderCell>
                    <TableHeaderCell>Pending Invoices</TableHeaderCell>
                    <TableHeaderCell>Uninvoiced Deals</TableHeaderCell>
                    
                </TableRow>
                {
                    filteredData.length > 0  && filteredData?.map((agentPayment, index) => {
                        return (
                            <React.Fragment key={index}>
                                <TableRow onClick={() => setFocusAgent(focusAgent === agentPayment ? null:agentPayment)}>
                                    <TableCell style={{gap: '3px', display:'flex', flexDirection:'column'}}>
                                        <div>{agentPayment.agentName}</div>
                                        <div style={{fontSize:'11px', color: 'gray'}}>{agentPayment.brokerage}</div>
                                    </TableCell>
                                    <TableCell>
                                        <StatusBadge status = {getCommissionStatus(agentPayment)}>
                                            {getCommissionStatus(agentPayment)}
                                        </StatusBadge>
                                    </TableCell>
                                    <TableCell>
                                        <div>
                                            {formatCurrency(agentPayment.outstandingFee)}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        {agentPayment.invoices.filter(invoice => invoice.status === "Pending").length}
                                    </TableCell>
                                    <TableCell>
                                        {agentPayment.uninvoicedDeals.length}
                                    </TableCell> 

                                </TableRow>
                            </React.Fragment>
                        )
                    })
                }
            </TableContainer>
            <CanvasSmall showSmallCanvas={focusAgent}>
                <CommissionBox
                    focusAgent = {focusAgent}
                    updateAgent = {updateAgent}
                    onClose = {() => setFocusAgent()}
                />
            </CanvasSmall>
        </Container>
    )
}

export default PaymentAgentTab