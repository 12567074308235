import React, { useState, useContext, useEffect } from "react";
import ProgressBar from "../progress-bar/progress-bar";
import FormInput from "../form-input/form-input";
import UploadSection from "../upload-file/upload-file";
import PopupForm from "../popup-form/popup-form";
import { SocketContext } from "../../context/socketContext";
import { toast } from "react-toastify";
import {
    Canvas,
    Title,
    WrapDiv,
    SectionTitle,
    Section,
    Heading,
    InputDivLabel,
    NextButton,
    Container,
    Header
} from './guarantor-form.styles'


const GuarantorForm = ({setApplicationData, applicationData, pageType}) => {
    const steps = ['Guarantor Form', 'Upload Documents']
    const [isFocus, setIsFocus] = useState(applicationData.signedApplication ? steps[1]:steps[0]) 
    const [docs, setDocs] = useState([...applicationData?.documents]);
    const [showPopupUrl, setShowPopupUrl] = useState(null)
    const [errors, setErrors] = useState({})
    const { subscribeToSignForm } = useContext(SocketContext);

    // console.log(applicationData)

    useEffect(() => {
        // Fetch initial chat history...

        // Subscribe to new chat messages
        const unsubscribe = subscribeToSignForm((data) => {
            // console.log(data)
            if (applicationData.guarantorId === data.entity_id && pageType === data.entity_type && data.application_id === applicationData.applicationId) {
                setShowPopupUrl(null);
                setIsFocus(steps[1]);
            }
        });

        return () => unsubscribe(); // Cleanup the subscription on unmount
    },[subscribeToSignForm]);

    const validateInputs = () => {
        let newErrors = {} 
        if (!applicationData.name) newErrors.name = 'Name is required';

        // Email validation
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!applicationData.email) {
            newErrors.email = 'Email is required';
        } else if (!emailRegex.test(applicationData.email)) {
            newErrors.email = 'Enter a valid email';
        }
        // Phone validation
        const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (!applicationData.phone) {
            newErrors.phone = 'Phone is required';
        } else if (!phoneRegex.test(applicationData.phone)) {
            newErrors.phone = 'Enter a valid phone number';
        }

        if(!applicationData?.cosignerDetails.guarantee) newErrors.guarantee = "Guarantee is required";
        if(!applicationData?.cosignerDetails.relationship) newErrors.relationship = "Relationship is required";
        if(!applicationData?.cosignerDetails.ssn) newErrors.ssn = "SSN is required";
        if(!applicationData?.cosignerDetails.address) newErrors.address = "Address is required"
        if(!applicationData?.cosignerDetails.salary) newErrors.salary = "Salary is required"
        if(!applicationData?.cosignerDetails.employerName) newErrors.employerName = "Employer name is required"
        if(!applicationData?.cosignerDetails.employmentDuration) newErrors.employmentDuration = "Duration is required"
        if(!applicationData?.cosignerDetails.designation) newErrors.designation = "Designation is required"
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const submitApplication = async () => {
        // console.log(validateInputs())
        if(validateInputs()){
            // console.log(applicationData)
            toast.info('Generating Application Form, Please hold for a few seconds');
            const apiEndpoint = "/api/application/guarantor/form"
            try {
                const response = await fetch(apiEndpoint, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(applicationData), // Convert the JavaScript object to a JSON string
                });
            
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }      

                // TODO: ZOHO INTEGRATION FOR SIGNING OF APP
                // TODO: ADD ZOHO SIGN URL POPUP FOR SIGN AND THEN PROCEED FUNCTIONALITY
                const data = await response.json();
                setShowPopupUrl(data.embed_token)
                // setShowPopupUrl('https://google.com')
                // setIsFocus(steps[1])
            } catch (error) {
                console.error('Error during API call:', error);
            }
        }
    }

    // const handleSign = () => {
    //     setShowPopupUrl();
    //     setIsFocus(steps[1]);
    // }

    const uploadDocs = async () => {
        // console.log(docs, applicationData.requiredDocs)
        // console.log(docs, requiredDocs)
        const allDocsPresent = applicationData.requiredDocs.filter(docName => docName !== "Guarantor Form").every(docName => 
            docs.some(doc => doc.documentType === docName && doc.value && doc.value !== '')
        );
        // console.log(allDocsPresent)
        if (!allDocsPresent ) {
            alert('Please upload all required documents.');
            return;
        }

        const formData = new FormData();
        formData.append('applicantId', applicationData.applicantId);
        formData.append('applicationId', applicationData.applicationId);
        formData.append('guarantorId', applicationData.guarantorId);
        docs.forEach(doc => {
            if (doc.value && doc.documentType && doc.documentType!=='Guarantor Form') {
                formData.append(doc.documentType, doc.value);
            }
        });

        try {
            toast.info('Submitting documents, please allow a moment to upload');
            const response = await fetch('/api/application/documents', {
              method: 'POST',
              body: formData,
            });
        
            if (!response.ok) {
              // If the response status code is not OK, throw an error to be caught by the catch block
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
        
            const data = await response.json();
            // setShowPopupUrl(data.embed_token);
            setIsFocus('Submitted');
            // Handle your response data here
            // console.log(data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            // Handle the error here
        }
    };


    return (
        <Canvas isFocus= {isFocus}>
            {
                isFocus !== 'Submitted' &&
                <>
                    <Title>Guarantor Application Form</Title>
                    <ProgressBar
                        steps={steps}
                        currentStepNumber = {steps.indexOf(isFocus)}
                    />
                </>
            }
            {
                isFocus === steps[0]?
                <>
                    <Section>
                        <SectionTitle>Personal Details</SectionTitle>
                        <WrapDiv>
                            <FormInput
                                label = "Name"
                                type = 'text'
                                defaultValue={applicationData.name}
                                handleChange={(value) => setApplicationData(prev => ({...prev, name: value}))}
                                isRequired={true}
                                flexBasis={true}
                                error={errors.name}
                            />
                            <FormInput
                                label = "Email"
                                type = 'email'
                                defaultValue={applicationData.email}
                                isRequired={true}
                                disabled={true}
                                flexBasis={true}
                                error={errors.email}
                            />
                            <FormInput
                                label = "Phone"
                                type = 'phone'
                                defaultValue={applicationData.phone}
                                isRequired={true}
                                handleChange={(value) => setApplicationData(prev => ({...prev, phone: value}))}
                                flexBasis={true}
                                error={errors.phone}
                            />
                            <FormInput
                                label = "Guarantor For"
                                type = 'text'
                                defaultValue={applicationData?.cosignerDetails?.guarantee}
                                isRequired={true}
                                handleChange={(value) => setApplicationData(prev => ({...prev, cosignerDetails: { ...prev?.cosignerDetails, guarantee: value}}))}
                                flexBasis={true}
                                error={errors.guarantee}
                            />
                            <FormInput
                                label = "Relationship with Applicant"
                                type = 'text'
                                defaultValue={applicationData?.cosignerDetails?.relationship}
                                isRequired={true}
                                handleChange={(value) => setApplicationData(prev => ({...prev, cosignerDetails: { ...prev?.cosignerDetails, relationship: value}}))}
                                flexBasis={true}
                                error={errors.relationship}
                            />
                            <FormInput
                                label = "Social Security"
                                type = 'text'
                                defaultValue={applicationData?.cosignerDetails?.ssn}
                                isRequired={true}
                                handleChange={(value) => setApplicationData(prev => ({...prev, cosignerDetails: { ...prev?.cosignerDetails, ssn: value}}))}
                                flexBasis={true}
                                error={errors.ssn}
                            />
                            <FormInput
                                label = "Current Address"
                                type = 'text'
                                defaultValue={applicationData?.cosignerDetails?.address}
                                isRequired={true}
                                handleChange={(value) => setApplicationData(prev => ({...prev, cosignerDetails: { ...prev?.cosignerDetails, address: value}}))}
                                flexBasis={true}
                                error={errors.address}
                            />
                        </WrapDiv>
                    </Section>
                    <Section>
                        <SectionTitle>Employment Details</SectionTitle>
                        <WrapDiv>
                            <FormInput
                                label = "Designation"
                                type = 'text'
                                defaultValue={applicationData?.cosignerDetails?.designation}
                                isRequired={true}
                                handleChange={(value) => setApplicationData(prev => ({...prev, cosignerDetails: { ...prev?.cosignerDetails, designation: value}}))}
                                flexBasis={true}
                                error={errors.designation}
                            />
                            <FormInput
                                label = "Employer Name"
                                type = 'text'
                                defaultValue={applicationData?.cosignerDetails?.employerName}
                                isRequired={true}
                                handleChange={(value) => setApplicationData(prev => ({...prev, cosignerDetails: { ...prev?.cosignerDetails, employerName: value}}))}
                                flexBasis={true}
                                error={errors.employerName}
                            />
                            <FormInput
                                label = "Employer Address"
                                type = 'text'
                                defaultValue={applicationData?.cosignerDetails?.employerAddress}
                                isRequired={true}
                                handleChange={(value) => setApplicationData(prev => ({...prev, cosignerDetails: { ...prev?.cosignerDetails, employerAddress: value}}))}
                                flexBasis={true}
                                error={errors.employerAddress}
                            />
                            <FormInput
                                label = "Employment Duration"
                                type = 'text'
                                defaultValue={applicationData?.cosignerDetails?.employmentDuration}
                                isRequired={true}
                                handleChange={(value) => setApplicationData(prev => ({...prev, cosignerDetails: { ...prev?.cosignerDetails, employmentDuration: value}}))}
                                flexBasis={true}
                                error={errors.employmentDuration}
                            />
                            <FormInput
                                label = "Annual Salary"
                                type = 'text'
                                defaultValue={applicationData?.cosignerDetails?.salary}
                                isRequired={true}
                                handleChange={(value) => setApplicationData(prev => ({...prev, cosignerDetails: { ...prev?.cosignerDetails, salary: value}}))}
                                flexBasis={true}
                                error={errors.salary}
                            />
                            <FormInput
                                label = "Other Income"
                                type = 'text'
                                defaultValue={applicationData?.cosignerDetails?.otherIncome}
                                isRequired={false}
                                handleChange={(value) => setApplicationData(prev => ({...prev, cosignerDetails: { ...prev?.cosignerDetails, otherIncome: value}}))}
                                flexBasis={true}
                                error={errors.otherIncome}
                            />
                        </WrapDiv>
                    </Section>
                    <NextButton onClick={submitApplication}>Sign Form & Continue</NextButton>
                </>
                :
                isFocus === steps[1]?
                <>
                    <WrapDiv style = {{gap:'20px'}}>
                        {
                            applicationData?.requiredDocs.filter(doc => doc !== "Guarantor Form").map((doc, index) => (
                                <div key ={index} style={{flex: '1', display:'flex', flexDirection:'column', gap:'10px', width:'fit-content', flexBasis:'300px'}}>
                                    <div>
                                        <Heading>Upload your {doc}<span style={{color: 'red'}}>*</span></Heading>
                                        <InputDivLabel>(Max. File size: 25 MB and it must be .pdf)</InputDivLabel>
                                    </div>
                                    <UploadSection
                                        index = {index}
                                        setDocs = {setDocs}
                                        doc = {doc}
                                        initialFile={applicationData.documents?.find(document => document.documentType === doc && document.value !== "")}
                                    />
                                </div>
                            ))
                        }
                    </WrapDiv>
                    <NextButton onClick={uploadDocs}>Submit</NextButton>
                </>
                :
                isFocus === "Submitted" &&
                <Container>
                    <Header>Thank You for Submitting Your Application!</Header>
                </Container>
            }
            <PopupForm
                showPopupUrl = {showPopupUrl}
                docType = {'Guarantor Form'}
                // handleButton = {handleSign}
                handleClose={() => console.log('Cannot Close')}
            />
        </Canvas>
    )
}

export default GuarantorForm