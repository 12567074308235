import React, {useState, useEffect, useContext} from "react";
import ReviewComponent from './review-modality/review-modality'
import AssignComponent from './assign-modality/assign-modality'
import AdditionalDocs from "./additional-docs/additional-docs";
import ApproveModality from "./approve-modality/approve-modality";
import ApplicationCheckUpdate from "../application-check-update/application-check-update.jsx";
import UpdateRentModal from "../update-rent-modal/update-rent-modal.jsx";
import { formatDate } from '../../utilities/utility';

import {
    ModalOverlay,
    ModalWrapper,
    Title,
    CloseButton,
    FlexDiv,
    SpaceDiv,
    FlexDivGapAlign,
    PropertyImg,
    FlexDivColumn,
    PropertyAddress,
    FlexDivGapSmall,
    BulletIcon,
    StatusBadge,
    PropertyPrice,
    // ApplicationPackageDiv,
    FMRPaid,
    FMRUnPaid
} from './application-modality.styles'

import { ReactComponent as Truck } from '../../assets/bi_truck.svg';
import { ReactComponent as Wallet } from '../../assets/Wallet.svg';
import { ReactComponent as Arrow } from '../../assets/Arrow - Price change.svg'
import { ReactComponent as ArrowDown } from '../../assets/Arrow - Price decrease.svg'

// import { ReactComponent as Download } from '../../assets/Download.svg'
import { ApiContext } from "../../context/apiContext.js";
import { toast } from "react-toastify";


const ApplicationModal = ({applicationId, onClose, activeTab, setActiveTab, updateApplication}) => {
    const api = useContext(ApiContext)
    // Local State to handle modality opening and closing
    const [focusApplication, setFocusApplication] = useState({});    
    const [updateRentModal, setUpdateRentModal] = useState(false);
    const [fmrPaidModal, setFmrPaidModal] = useState(false);

    // Handling of modality opening and closing
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await api.get(`/api/application/detail`,{applicationId: applicationId});
                setFocusApplication(data); //Todo: Remove applications
                // console.log(data)
            } catch (error) {
                console.error('Fetch error:', error);
            }
        };
    
        fetchData();

        return  () => console.log('I am unmounting')
         
      }, [applicationId]);


    const handleClose = () => {
        // console.log('I am closing')
        onClose(); // This will change the isOpen prop
    };

    // const handleApplicationDownload = async () => {
    //     try {
    //         const response = await fetch(focusApplication?.applicationPackage, {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/octet-stream',
    //             },
    //         });
      
    //         if (!response.ok) throw new Error(`Error: ${response.statusText}`);
      
    //         const blob = await response.blob();
    //         const downloadUrl = window.URL.createObjectURL(blob);
    //         const link = document.createElement('a');
    //         link.href = downloadUrl;
    //         link.setAttribute('download', `Apps_${focusApplication?.property.address}`); // or any other extension
    //         document.body.appendChild(link);
    //         link.click();
    //         link.parentNode.removeChild(link);
    //     } catch (err) {
    //             toast.error(`Failed to download file`);
    //     }
    // }

    // console.log(focusApplication)

    // console.log(focusApplication?.id)
    if (!focusApplication?.id) return null;

    return (
        <ModalOverlay onClick={handleClose}>
            <ModalWrapper
                onClick={(e) => e.stopPropagation()}
                focusTab = {activeTab}
            >
                <div style = {{padding: '30px 30px 0px 30px', display:'flex', flexDirection:'column', gap:'20px'}}>
                    <SpaceDiv>
                        <Title>{activeTab == 'Additional Docs'  ? 'Request Additional Documents':'Application Review'}</Title>
                        <FlexDiv>
                            <span style={{fontSize:"14px"}}>Close</span>
                            <CloseButton onClick={handleClose}>X</CloseButton>
                        </FlexDiv>
                    </SpaceDiv>
                    {
                        activeTab !== 'Additional Docs' &&
                        <SpaceDiv>
                            <FlexDivGapAlign>
                                <PropertyImg src = {focusApplication?.property?.imageUrl}/>
                                <FlexDivColumn>
                                    <FlexDiv>
                                        <PropertyAddress>{focusApplication?.property?.address}</PropertyAddress>
                                        <StatusBadge status={focusApplication?.status}>
                                            <BulletIcon />
                                            {focusApplication?.status}
                                        </StatusBadge>
                                        {
                                            focusApplication?.depositPaid ?
                                            <FMRPaid>
                                                <BulletIcon/>
                                                Deposit Paid
                                            </FMRPaid>
                                            :
                                            focusApplication?.status !== "Approved" &&
                                            <FMRUnPaid onClick={() => setFmrPaidModal(true)}>
                                                {/* <BulletIcon/> */}
                                                Confirm Deposit Payment
                                            </FMRUnPaid>
                                        }
                                        <FMRUnPaid onClick={() => setUpdateRentModal(true)}>
                                            {/* <BulletIcon/> */}
                                            Update Rent
                                        </FMRUnPaid>
                                    </FlexDiv>
                                    <FlexDivGapSmall>
                                        <PropertyPrice 
                                            priceUp = {focusApplication?.offerPrice > focusApplication?.property?.price}
                                            priceDown = {focusApplication?.offerPrice < focusApplication?.property?.price}
                                        >
                                            {
                                                focusApplication?.offerPrice > focusApplication?.property?.price  ?
                                                <><Wallet stroke='#1CCB00'/>${focusApplication?.offerPrice}<Arrow/></>
                                                :
                                                focusApplication?.offerPrice < focusApplication?.property?.price  ?
                                                <><Wallet stroke='#FF0000'/>${focusApplication?.offerPrice}<ArrowDown/></>
                                                :
                                                <><Wallet stroke='#747474'/>${focusApplication?.offerPrice}</>
                                            }
                                        </PropertyPrice>
                                        <PropertyPrice><Truck fill='#747474'/>{formatDate(new Date(focusApplication?.property?.moveIn)).formattedDate}</PropertyPrice>
                                    </FlexDivGapSmall>
                                </FlexDivColumn>
                            </FlexDivGapAlign>
                            {/* <FlexDivColumn style = {{flex: '0', alignItems:'center'}}>
                                {
                                    focusApplication?.applicationPackage && focusApplication?.applicationPackage !== '' &&
                                    <ApplicationPackageDiv onClick={handleApplicationDownload}>Application Package <Download/></ApplicationPackageDiv>
                                }
                            </FlexDivColumn> */}
                        </SpaceDiv>
                    }
                </div>
                {
                    focusApplication &&
                        (activeTab === 'Review' ? 
                        <ReviewComponent 
                            focusApplication = {focusApplication}
                            setActiveTab = {setActiveTab}
                            updateApplication = {updateApplication}
                            setFmrPaidModal = {setFmrPaidModal}
                        /> 
                        : 
                        activeTab === 'Assign' ?
                        <AssignComponent 
                            setActiveTab = {setActiveTab}
                            updateApplication = {updateApplication}
                            applicationId = {focusApplication?.id}
                        />
                        :
                        activeTab === "Approve"?
                        <ApproveModality
                            setActiveTab = {setActiveTab}
                            updateApplication = {updateApplication}
                            applicationId = {focusApplication?.id}
                            leaseDates = {{from: focusApplication?.requestedMoveIn, to: focusApplication?.requestedMoveOut}}
                            offerMoveinDeposits = {focusApplication?.offerMoveinDeposits}
                            depositPaid={focusApplication?.depositPaid}
                            depositPaidType = {focusApplication?.depositPaidType}
                        />
                        :
                        activeTab === "Additional Docs" &&
                        <AdditionalDocs
                            applicants = {focusApplication?.applicants}
                            applicationId = {focusApplication?.id}
                            setActiveTab = {setActiveTab}
                            updateApplication = {updateApplication}
                        />)
                }
                {
                    fmrPaidModal &&
                    <ApplicationCheckUpdate
                        applicationId={focusApplication?.id}
                        updateApplication={updateApplication}
                        focusApplication = {focusApplication}
                        setFocusApplication = {setFocusApplication}
                        onClose={() => setFmrPaidModal(false)}
                    />
                }
                {
                    updateRentModal && 
                    <UpdateRentModal
                        focusApplication = {focusApplication}
                        updateApplication = {updateApplication}
                        onClose = {() => setUpdateRentModal(false)}
                        setFocusApplication = {setFocusApplication}
                    />
                }
            </ModalWrapper>
        </ModalOverlay>
    )
}

export default ApplicationModal