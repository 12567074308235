import React, {useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import ImageModal from "../../components/image-modals/image-modals";
import AddInvoiceAndW9 from "../../components/add-Invoice-w9/add-invoice-w9";
// import data from './data.json'
import { formatCurrency, toProperCase } from "../../utilities/utility";
import {
    Canvas, LogoStyled, SidePanel, AgentTitle, SummaryDiv, Title, Button,
    AgentItem, GridContainer, AgentKeyItem, SummaryTitle, MainPanel, TabContentHeader, TabHolder, Tab,
    BorderContainer, ItemContainer, Heading, DocumentRow, DocumentType, PreviewContainer, StatusBadge,
    FlexDiv, DocumentButton
} from './agent-dashboard.pages.styles'

import { ReactComponent as LogoDark } from '../../assets/Leasehub grey.svg';


const AgentDashboard = () => {
    const [focusTab, setFocusTab] = useState('Applications') //Applications // Showings: maybe in the future
    const [agentDetails, setAgentDetails] = useState()
    const [isDocModalOpen, setisDocModalOpen] = useState(false);
    const [isAddInvoiceModalOpen, setIsAddInvoiceModalOpen] = useState(false)
    const [modalImageUrl, setModalImageUrl] = useState('');

    const {internalAgentId, type} = useParams();
    console.log(type)
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            let agentPrompt;

            // Retrieve the agent ID from session storage
            const storedAgentId = sessionStorage.getItem("authenticatedAgentId");

            if (storedAgentId ) {
                // You could also retrieve a stored agent ID from context or localStorage if needed
                agentPrompt = storedAgentId; 
            } else {
                agentPrompt = prompt('Please enter your Real estate ID to continue:');
                if (!agentPrompt) {
                    console.error("No agent ID provided.");
                    return; // Stop the execution if no agent ID is provided
                }
                sessionStorage.setItem("authenticatedAgentId", agentPrompt);
            }
            const { hostname } = window.location;
            const subdomain = hostname.split('.')[0];

            const queryParams = new URLSearchParams({
                agentId: agentPrompt,
                internalAgentId: internalAgentId,
                company: subdomain
            })
            try {
                let response = await fetch(`/api/agent/activity_detail?${queryParams.toString()}`); // ToDO: ChANGE API BACK
                if (response.ok) {            
                    let data = await response.json();
                    setAgentDetails(data);
                    if(type === "application") setFocusTab("Applications")
                    else if(type === "deal") setFocusTab("Deals")
                    else if(type === "invoice") setFocusTab("Invoices")
                } else {
                    // Handle errors, e.g., response status not OK
                    console.error("Failed to fetch properties:", response.status);
                }
            } catch (error) {
                // Handle network errors or other fetch issues
                console.error("Failed to fetch properties:", error);
            } 
        }

        fetchData();
    },[])

    useEffect(() => {
        // Clear session storage when the window/tab is closed
        const handleUnload = () => {
            sessionStorage.removeItem("authenticatedAgentId");
        };
    
        // Attach event listener to window unload
        window.addEventListener('beforeunload', handleUnload);
    
        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, []);

    const openDocModal = (imageUrl) => {
        setModalImageUrl(imageUrl);
        setisDocModalOpen(true);
    }

    const closeDocModal = () => {
        setisDocModalOpen(false);
        setModalImageUrl('');
    }

    const openAddInvoiceModal = () => {
        setIsAddInvoiceModalOpen(true)
    }

    const closeAddInvoiceModal = () => {
        setIsAddInvoiceModalOpen(false)
    }

    const openApplication = (applicationId) => {
        navigate(`/application-status/${applicationId}`)
    }

    if(!agentDetails) return;

    return (
        <Canvas>
            <SidePanel>
                <LogoStyled as={LogoDark}/>
                <AgentTitle>{agentDetails?.agentName}</AgentTitle>
                <SummaryDiv>
                    <GridContainer>
                        <AgentKeyItem>Brokerage:</AgentKeyItem>
                        <AgentItem>{agentDetails?.brokerage}</AgentItem> 
                        <AgentKeyItem>Outstanding Amount:</AgentKeyItem>
                        <AgentItem>{formatCurrency(agentDetails?.outstandingFee)}</AgentItem>
                    </GridContainer>
                    <SummaryTitle>Deal Summary</SummaryTitle>
                    <GridContainer>
                        {
                            agentDetails?.uninvoicedDeals.filter(deal => deal.paymentStatus === "Completed").length > 0 &&
                            <>
                                <AgentKeyItem>Un-invoiced Deals:</AgentKeyItem>
                                <AgentItem>{agentDetails?.uninvoicedDeals.length}</AgentItem>
                            </>
                        }
                        <AgentKeyItem>Deals with Payments due:</AgentKeyItem>
                        <AgentItem>{agentDetails?.uninvoicedDeals.filter(deal => deal.paymentStatus === "Pending").length}</AgentItem>
                    </GridContainer>
                    {
                        agentDetails?.invoices.length > 0 &&
                        <>
                            <SummaryTitle>Invoice Summary</SummaryTitle>
                            <GridContainer>
                                <AgentKeyItem>Pending Invoices:</AgentKeyItem>
                                <AgentItem>{agentDetails?.invoices.filter(invoice => invoice.status === "Pending").length}</AgentItem>
                                <AgentKeyItem>Cleared Invoices:</AgentKeyItem>
                                <AgentItem>{agentDetails?.invoices.filter(invoice => invoice.status === "Completed").length}</AgentItem>
                            </GridContainer>
                        </>
                    }
                    <SummaryTitle>Application Summary</SummaryTitle>
                    <GridContainer>
                        <AgentKeyItem>Submitted Applications:</AgentKeyItem>
                        <AgentItem>
                            {agentDetails?.applications.filter(application => application.status !== "Pending" || application.status !== "Incomplete").length}
                        </AgentItem>
                        <AgentKeyItem>Pending Applications:</AgentKeyItem>
                        <AgentItem>
                            {agentDetails?.applications.filter(application => application.status === "Pending").length}
                        </AgentItem>
                    </GridContainer>
                </SummaryDiv>
            </SidePanel>
            <MainPanel>
                <Title>Agent Detail Panel</Title>
                <TabContentHeader>    
                    <TabHolder>
                        <Tab onClick={() => setFocusTab('Applications')} active={focusTab === 'Applications'}>Applications</Tab>
                        <Tab onClick={() => setFocusTab('Deals')} active={focusTab=== "Deals"}>Deals</Tab>
                        {
                            agentDetails?.invoices.length > 0 &&
                            <Tab onClick={() => setFocusTab('Invoices')} active={focusTab === 'Invoices'}>Invoices</Tab>
                        }
                    </TabHolder>
                </TabContentHeader>
                {
                    focusTab === "Deals" &&
                    <BorderContainer>
                        {
                            agentDetails?.uninvoicedDeals.filter(deal => deal.paymentStatus === "Completed").length > 0 &&
                            <ItemContainer>
                                <Heading>
                                    <div>Uninvoiced Deals</div>
                                    <Button 
                                        onClick={openAddInvoiceModal}
                                        style = {{background: '#E45A5C'}}
                                    >
                                        Upload Invoice & w9
                                    </Button>
                                </Heading>
                                {
                                    agentDetails?.uninvoicedDeals.filter(deal => deal.paymentStatus === "Completed").map(deal => 
                                        <DocumentRow>
                                            <DocumentType className="title">{deal.propertyAddress}</DocumentType>
                                            <DocumentType className="title">{formatCurrency(deal.commission)}</DocumentType>
                                        </DocumentRow>
                                    )
                                }
                                <PreviewContainer>
                                    <DocumentType className="total-title">Total Commission:</DocumentType>
                                    <DocumentType className="total-title">
                                        {
                                            formatCurrency(agentDetails?.uninvoicedDeals
                                                .filter(deal => deal.paymentStatus === "Completed")
                                                .reduce((agg,deal) => agg+deal.commission,0))
                                        }
                                    </DocumentType>
                                </PreviewContainer>
                            </ItemContainer>
                        }
                        {
                            agentDetails?.uninvoicedDeals.filter(deal => deal.paymentStatus !== "Completed").length > 0 &&
                            <ItemContainer>
                                <Heading>Deals with Move-in Payments Due to Management</Heading>
                                {
                                    agentDetails?.uninvoicedDeals.filter(deal => deal.paymentStatus !== "Completed").map(deal => 
                                        <DocumentRow>
                                            <DocumentType className="title">{deal.propertyAddress}</DocumentType>
                                            <DocumentType className="title">{formatCurrency(deal.amountDue)}</DocumentType>
                                        </DocumentRow>
                                    )
                                }
                                <PreviewContainer>
                                    <DocumentType className="total-title">Total Move-in Payment Due:</DocumentType>
                                    <DocumentType className="total-title">
                                        {
                                            formatCurrency(agentDetails?.uninvoicedDeals
                                                .filter(deal => deal.paymentStatus !== "Completed")
                                                .reduce((agg,deal) => agg+deal.amountDue,0))
                                        }
                                    </DocumentType>
                                </PreviewContainer>
                            </ItemContainer>
                        }
                        {
                            agentDetails?.uninvoicedDeals.length === 0 &&
                            <ItemContainer>There are no current deals</ItemContainer>
                        }
                    </BorderContainer>
                }
                {
                    focusTab === "Applications" && 
                    <BorderContainer>
                        {
                            agentDetails.applications.filter(application => application.status === "Pending" || application.status === "Incomplete").length > 0 &&
                            <ItemContainer>
                                <Heading>Pending & Incomplete Applications</Heading>
                                {
                                    agentDetails.applications.filter(application => application.status === "Pending" || application.status === "Incomplete")
                                    .map(application =>
                                        <DocumentRow className="hover" onClick={() => openApplication(application.applicationId)}>
                                            <DocumentType className="title">
                                                {application.property.address} - ({application.applicants[0]}{application.applicants.length >1 && " and Others"})
                                            </DocumentType>
                                            <DocumentType className="title">Offer: {formatCurrency(application.offerPrice)}</DocumentType>
                                        </DocumentRow>
                                    )
                                }
                            </ItemContainer>
                        }
                        {
                            agentDetails.applications.
                            filter(application => application.status !== "Pending" && application.status !== "Incomplete" 
                                && application.status !== "Rejected" && application.status !== "Withdrawn" && application.status !== "Approved" )
                            .length > 0 &&
                            <ItemContainer>
                                <Heading>Submitted Applications</Heading>
                                {
                                    agentDetails.applications.filter(application => application.status !== "Pending" && application.status !== "Incomplete" 
                                        && application.status !== "Rejected" && application.status !== "Withdrawn" && application.status !== "Approved" )
                                    .map(application =>
                                        <DocumentRow className="hover" onClick={() => openApplication(application.applicationId)}>
                                            <DocumentType className="title">
                                                {application.property.address} - ({application.applicants[0]}{application.applicants.length >1 && " and Others"})
                                            </DocumentType>
                                            <DocumentType className="title">Offer: {formatCurrency(application.offerPrice)}</DocumentType>
                                        </DocumentRow>
                                    )
                                }
                            </ItemContainer>
                        }
                        {
                            agentDetails.applications.
                            filter(application => application.status === "Approved" )
                            .length > 0 &&
                            <ItemContainer>
                                <Heading>Approved Applications</Heading>
                                {
                                    agentDetails.applications.filter(application =>application.status === "Approved" )
                                    .map(application =>
                                        <DocumentRow className="hover" onClick={() => openApplication(application.applicationId)}>
                                            <DocumentType className="title">
                                                {application.property.address} - ({application.applicants[0]}{application.applicants.length >1 && " and Others"})
                                            </DocumentType>
                                            <DocumentType className="title">Offer: {formatCurrency(application.offerPrice)}</DocumentType>
                                        </DocumentRow>
                                    )
                                }
                            </ItemContainer>
                        }
                        {
                            agentDetails.applications.filter(application => application.status === "Rejected" || application.status === "Withdrawn").length > 0 &&
                            <ItemContainer>
                                <Heading>Rejected & Withdrawn Applications</Heading>
                                {
                                    agentDetails.applications.filter(application =>  application.status === "Rejected" || application.status === "Withdrawn")
                                    .map(application =>
                                        <DocumentRow className="hover" onClick={() => openApplication(application.applicationId)}>
                                            <DocumentType className="title"> 
                                                {application.property.address} - ({application.applicants[0]}{application.applicants.length >1 && " and Others"})
                                            </DocumentType>
                                            <DocumentType className="title">Offer: {formatCurrency(application.offerPrice)}</DocumentType>
                                        </DocumentRow>
                                    )
                                }
                            </ItemContainer>
                        }
                    </BorderContainer>
                }
                {
                    focusTab === "Invoices" && 
                    <BorderContainer>
                        {
                            agentDetails?.invoices.map((invoice)=> 
                                <ItemContainer>
                                    <Heading>
                                        <div>{toProperCase(invoice.invoiceName)}</div>
                                        <StatusBadge status={invoice.status}>{invoice.status}</StatusBadge>
                                    </Heading>
                                    {
                                        invoice.deals.map((deal) => 
                                            <DocumentRow key={deal.dealId}>
                                                <DocumentType className="title">{deal.propertyAddress}</DocumentType>
                                                <DocumentType className="title">{formatCurrency(deal.amount)}</DocumentType>
                                            </DocumentRow>
                                        )
                                    }
                                    <PreviewContainer>
                                        <FlexDiv>
                                            {
                                                invoice.invoiceFile && 
                                                <DocumentButton onClick={() => openDocModal(invoice.invoiceFile)}>Invoice</DocumentButton>
                                            }
                                            {
                                                invoice.w9File && 
                                                <DocumentButton onClick={() => openDocModal(invoice.w9File)}>W9</DocumentButton>
                                            }
                                            {
                                                invoice.checkFile && 
                                                <DocumentButton onClick={() => openDocModal(invoice.checkFile)}>Check</DocumentButton>
                                            }
                                        </FlexDiv>
                                        <DocumentType className="total-title">Total: {formatCurrency(invoice.totalAmount)}</DocumentType>
                                    </PreviewContainer>
                                </ItemContainer>
                            )
                        }
                        {
                            agentDetails?.invoices.length === 0 &&
                            <ItemContainer>There are no invoices</ItemContainer>
                        }
                    </BorderContainer>
                }
            </MainPanel>
            {
                isDocModalOpen &&
                <ImageModal onClose={closeDocModal}>
                    <img src={modalImageUrl} alt="Zoomed Check" />
                </ImageModal>
            }
            {
                isAddInvoiceModalOpen &&
                <AddInvoiceAndW9
                    agentId={agentDetails.agentId}
                    deals = {agentDetails.uninvoicedDeals.filter(deal => deal.paymentStatus === "Completed")}
                    onClose={closeAddInvoiceModal}
                    setAgentDetails={setAgentDetails}
                />
            }
        </Canvas>
    )
}

export default AgentDashboard