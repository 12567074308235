import React, {useState} from "react";
import FormInput from "../../form-input/form-input";
import moment from "moment";
import { Section, SectionTitle, Label, NextButton, DateFilterContainer, Heading, WrapDiv, Currency, PreviousButton, GapDiv } from '../shared-styles';
import { dateDiff } from "../../../utilities/utility";

const PropertyForm = ({property, applicationData, handleChange, setIsFocus}) => {
    const [errors, setErrors] = useState({})
    const currentDate = moment().format('yyyy-MM-DD');

    const handleDepositChange = (key,value) => {
        const newDeposits = {
            ...applicationData.moveinDeposits,
            [key]: Number(value)
        }

        handleChange('moveinDeposits', newDeposits)
    }

    const handleDateChange = (key,val) => {
        handleChange(key,moment(val).format('YYYY-MM-DD'))
    }

    const validatePropertyInputs = () => {
        let newErrors = {};
        if (!applicationData.requestedMoveIn){
            newErrors.requestedMoveIn = 'Move in date is required';
        }else if (applicationData.requestedMoveIn < new Date()){
            newErrors.requestedMoveIn = 'Move in date cannot be earlier than today\'s date';
        }

        if (!applicationData.requestedMoveOut) newErrors.requestedMoveOut = 'Move out date is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const handleNext = () => {
        const {months, days} = dateDiff(applicationData.requestedMoveIn, applicationData.requestedMoveOut)
        handleChange('leaseDuration', `${months} months ${days} days`)

        if (validatePropertyInputs()) {
            setIsFocus('Document Handling')
        }
    }

    return (
        <>
            <SectionTitle>Property Lease Details</SectionTitle>
            <Section>
                <FormInput
                    id = "property address"
                    label={'Property Address'}
                    // isRequired={true}
                    type={'text'}
                    defaultValue={property.address}
                    disabled = {true}
                    // error={errors.agentName}
                />
            </Section>
            <DateFilterContainer>
                <Heading>Lease Tenure<span style={{color:'red'}}>*</span></Heading>
                <WrapDiv>
                        <Currency style = {{flex:'1'}}>Lease Start:
                            <FormInput
                                id="requestedMoveIn"
                                type="date"
                                value={moment(applicationData.requestedMoveIn).format('yyyy-MM-DD')}
                                defaultValue={moment(applicationData.requestedMoveIn).format('yyyy-MM-DD')}
                                handleChange={(value) => handleDateChange('requestedMoveIn', value)}
                                flexBasis={true}
                                error = {errors.requestedMoveIn}
                                isRequired={true}
                                minDate={currentDate}
                            />
                        </Currency>
                        <Currency style = {{flex:'1'}}>Lease End:
                            <FormInput
                                id="requestedMoveOut"
                                type="date"
                                value={moment(applicationData.requestedMoveOut).format('yyyy-MM-DD')}
                                defaultValue={moment(applicationData.requestedMoveOut).format('yyyy-MM-DD')}
                                min={new Date()}
                                handleChange={(value) => handleDateChange('requestedMoveOut', value)}
                                flexBasis={true}
                                error = {errors.requestedMoveOut}
                                isRequired={true}
                                minDate={currentDate}
                            />
                        </Currency>
                </WrapDiv>
            </DateFilterContainer>
            <Section>
                <div>
                    <Heading>Move In Payments<span style={{color:'red'}}>*</span></Heading>
                    <Label>
                        Please review and adjust the pre-filled amounts for 'First Month's Rent', 'Last Month's Rent', and other costs as needed. Your changes will update the proposed price automatically. Ensure all details are correct before submitting.
                    </Label>
                </div>
                <WrapDiv>
                    <Currency style = {{flex:'1'}}>
                        Base Rent
                        <FormInput
                            isRequired={false}
                            type={'currency'}
                            defaultValue={applicationData?.offerPrice}
                            value = {applicationData?.offerPrice}
                            flexBasis={true}
                            handleChange={(val) => handleChange("offerPrice", val)}
                        />
                    </Currency>
                    {
                        Object.entries(applicationData?.moveinDeposits || {})
                        .filter(([key, _]) => key !== "Deposit On Account")
                        .map(([key, value]) => (
                            <Currency style = {{flex:'1'}}>
                                {key === "Application Fee"? 'Application Fee/Person':key}
                                <FormInput
                                    isRequired={false}
                                    type={'currency'}
                                    defaultValue={value}
                                    value = {value}
                                    flexBasis={true}
                                    handleChange={(val) => handleDepositChange(key, val)}
                                />
                            </Currency>
                        ))
                    }
                    <Currency style = {{flex:'1'}}>
                        Deposit on Account
                        <FormInput
                            isRequired={false}
                            type={'currency'}
                            defaultValue={applicationData?.moveinDeposits?.["Deposit On Account"] || 0}
                            value = {applicationData?.moveinDeposits?.["Deposit On Account"]}
                            flexBasis={true}
                            handleChange={(val) => handleDepositChange("Deposit On Account", val)}
                        />
                    </Currency>
                </WrapDiv>
            </Section>
            <GapDiv>
                <PreviousButton onClick={() => setIsFocus('Broker')}>Previous</PreviousButton>
                <NextButton onClick={() => handleNext()}>Next</NextButton>
            </GapDiv>
        </>
    )
}

export default PropertyForm