import React, {useContext, useState} from "react";
import ReactDOM from 'react-dom';
import { useLocation } from "react-router-dom";
import { ApiContext } from "../../context/apiContext";
import Loader from "../loader/loader";
import {
    ModalBackdrop,
    ModalContainer,
    ModalHeader,
    FlexDiv,
    SpaceDiv,
    CloseButton,
    DownloadButton
} from './popup-form.styles'
import { toast } from "react-toastify";

const PopupForm = ({showPopupUrl, handleClose, docType, toSign = false, handleSign}) => {
    const api = useContext(ApiContext)
    const [isLoading, setIsLoading] = useState(false)
    const [isSigned, setIsSigned] = useState(false);
    const location = useLocation();
    const isApplication = location.pathname.includes('application-form')
    const isLeases = toSign

    if (!showPopupUrl) return null;

    const closeClick = (event) => {
        event.stopPropagation();
        handleClose()    
    }
    
    const handleSignClick = async () => {
        try {
            setIsLoading(true);
            await handleSign();
            setIsSigned(true);
        } catch(error){
            toast.error('Something went wrong')
        } finally{
            setIsLoading(false)
        }
    }

    return ReactDOM.createPortal(
        <ModalBackdrop onClick={closeClick}>
            <ModalContainer onClick = {(e) => e.stopPropagation()}>
                <SpaceDiv>
                    {/* {Todo: Add ${docType}  back to preview once fixed} */}
                    <ModalHeader>{isApplication ?'Sign Application Form': isLeases? `Sign Lease`:`Preview`}</ModalHeader>  
                    {
                        !(isApplication || isLeases) &&
                        <FlexDiv>
                            <span style={{fontSize:"14px"}}>Close</span>
                            <CloseButton onClick={closeClick}>X</CloseButton>
                        </FlexDiv>
                    }
                </SpaceDiv>
                {isLoading && <Loader/>}
                <iframe
                    style={{height:'100%',width:'100%', border:'1px solid #323232', borderRadius:'10px'}} 
                    src= {showPopupUrl}
                />
                {
                    isLeases && !isSigned ?
                    <DownloadButton onClick={handleSignClick}>Auto Affix Signature!</DownloadButton>
                    :
                    isLeases && isSigned &&
                    <DownloadButton onClick={closeClick}>Close Document</DownloadButton>
                }
            </ModalContainer>
        </ModalBackdrop>,
        document.getElementById('modal-root')
    );
};

export default PopupForm