import styled from "styled-components";

export const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5000;
`;

export const ModalContent = styled.div`
    background: white;
    padding: 20px;
    border-radius: 8px;
    
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 600px){
        width: 70%;
    }
`;



export const WrapDiv = styled.div`
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    gap: 10px;
`;

export const ButtonDiv = styled.div`
    // display: flex;
    // padding: 10px 30px 10px;
    // justify-content: flex-end;
    // gap: 20px;
    // bottom: 0;
    // position: absolute;
    // border-top: 1px solid #fafafa;
    // width: -webkit-fill-available;

    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #eee;
    padding-top: 10px;
    margin-top: 20px;
    gap: 10px;
`;

export const Heading = styled.div`
    color: var(--Greyish-Black, #323232);
    leading-trim: both;
    text-edge: cap;

    /* Title/Extra Small */
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;


export const NextButton = styled.button`
    background-color: #064274    ;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 12px;
    width: 250px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;

    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }
`;

export const PreviousButton = styled(NextButton)`
    // background-color: #F7F7F7;
    // color: #323232;
    // font-weight: 400;
    // // cursor: pointer;
    
    // &:hover{ 
    //     background-color: #dedede80;
    //     transition: background-color 0.2s;
    // }

    background: #E45A5C;
    color: #FFFFFF;
    &:hover {
        background-color: #B5373A; 
    }
`;