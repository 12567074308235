import styled, {css} from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center; /* Center the children */
  align-items: flex-start; /* Align items at the start vertically */
  height: -webkit-fill-available;
  gap: 30px;
  width: -webkit-fill-available;
  position: relative; /* To position CanvasSmall absolutely */
`;


const CanvasStyles = css`
  border-radius: 10px;
  background: var(--White, #FFF);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: auto;
  height: -webkit-fill-available;
  transition: all 0.5s ease; /* Smooth transition */
`;

export const CanvasSmall = styled.div`
  ${CanvasStyles}
  padding: 20px;
  width: 360px;
  position: absolute;
  overflow: auto;
  right: 0;
  top: 0;
  transform: ${({ showSmallCanvas }) => showSmallCanvas ? 'translateX(0)' : 'translateX(100%)'};
  visibility: ${({ showSmallCanvas }) => showSmallCanvas ? 'flex' : 'hidden'};
  transition: visibility 0.5s ease, transform 0.5s ease; /* Transition for sliding in */
`;

export const TableContainer = styled.div`
  ${CanvasStyles}
  width: ${({ showSmallCanvas }) => showSmallCanvas ? '64%' : '100%'}; /* Adjust width based on CanvasSmall visibility */
  transform: ${({ showSmallCanvas }) => showSmallCanvas ? 'translateX(-25%)' : 'translateX(0%)'};
  transition: all 0.5s ease; /* Smooth transition */
  padding: 0px 20px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
  overflow-x: auto; // Ensures the table is scrollable on smaller screens
  overflow-y: auto;
  position: relative;
  // overflow: visible;
`;


export const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
  width: 100%; // Make sure the row spans the full width
  box-sizing: border-box; // Include padding in the element's total width and height

  align-items: ${props => props.as === "header" ? 'baseline':'center'};

  &:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #f1f1f1;
  }

  &:nth-child(1){
    position: sticky;
    top: 0; /* Adjust if you have a top bar or padding you need to account for */
    background: white; /* Or any color that matches your table header's background */
    z-index: 1; /* Ensure
    position: relative;
    cursor: unset;
  }
`;

export const TableHeaderCell = styled.div`
  padding: 8px 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  min-width: 0; // Allows the cell to shrink below content size
  white-space: nowrap; // Prevents line breaks inside cells
  font-size: 14px;
  font-style: normal;
  line-height: normal;

  flex: 1; // Allocate equal space to each cell, adjust the number as needed for a different ratio
  text-align: left;

`;

export const TableCell = styled.div`
  text-align: left;
  // padding: 8px 0px;
  min-width: 0; // Allows the cell to shrink below content size
  white-space: nowrap; // Prevents line breaks inside cells
  overflow: hidden; // Hide content that overflows the cell
  text-overflow: ellipsis; // Add an ellipsis to truncated content

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  flex: 1;
`;

export const StatusBadge = styled.div`

  ${({ status }) => status && css`
    background-color: ${statusColors[status].background};
    color: ${statusColors[status].color};
  `}
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 11px;
  font-weight: 600;
  user-select: none;
  width: fit-content;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
`;

const statusColors = {
  "Completed": { background: '#E3FFE6', color: '#1CCB00' },
  "Pending": {background: '#FFD1D1', color: '#D8000C' },
  "Partially Complete": {background: '#FFF4E5', color: '#FFA500'}
};