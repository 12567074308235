import styled from "styled-components";

export const Canvas = styled.div`
    padding: 30px;
    gap: 30px;
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    overflow: auto;

    @media screen and (max-width: 800px) {
        // max-width: 100%;
        // min-width: 100%;
        padding: 20px;
        width: unset;
        gap: 20px;
        overflow: unset;
    }
`;

export const Title = styled.div`
    color: #2C2C2C;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media screen and (max-width: 800px) {
       text-align: center;
    }
`;





export const AppointmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: fit-content;
  margin: ${props => props.submitted && 'auto'};

  @media screen and (max-width: 800px) {
    gap: 15px;
    height: -webkit-fill-available;
  }
`;







