import styled from 'styled-components';

export const  ModalBackdrop = styled.div`
    position: fixed;
    z-index: 1040;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const  ModalContainer = styled.div`
    background: white;
    padding: 20px;
    border-radius: 7px;
    min-width: 350px;
    max-height: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: auto;
    max-height: 90%;
    width: 70%;
    gap: 10px;
    max-width: 650px;
    display: flex;
    flex-direction: column;

    p {
        font-weight: 500;
        font-size: 16px;
        font-family: Avenir;
        margin: unset;
    }
`;

export const  ModalHeader = styled.h2`
    margin: unset;
`;


export const ConfirmButton = styled.button`
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    border: unset;
    border-radius: 5px;
    background:  #064274;
    color: #FFFFFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    width: 120px;
    
    &:hover {
        background-color: #002456;
        transition: background-color 0.2s;
    }

    @media screen and (max-width: 600px){
        width: -webkit-fill-available;
        font-size: 14px;
    }
`;

export const CancelButton = styled(ConfirmButton)`
    background: #E45A5C;
    color: #FFFFFF;
    &:hover {
        background-color: #B5373A; 
       
    }
`;


export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #eee;
  padding-top: 10px;
  margin-top: 20px;
  gap: 10px;
`;

export const WrapDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

export const Currency = styled.div`
    font-size: 10px;
    color: #2C2C2C;
`;


export const Label = styled.label`
    color: #A4A4A4;
    font-size: 14px;
    font-weight: 400;
`;

export const TableContainer = styled.div`
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
`;

// Table Row
export const TableRow = styled.div`
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    align-items: center;
    padding: 4px 10px;
    border-bottom: 1px solid #ddd;
    background-color: ${({ header }) => (header ? '#f7f7f7' : 'white')};
    font-weight: ${({ header }) => (header ? '600' : '400')};
    &:last-child {
        border-bottom: none;
    }
`;

// Table Cell
export const TableCell = styled.div`
//   flex: 1;
    text-align: left;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:first-child {
        flex:1.5;
        @media only screen and (max-width: 1000px){
            flex: 1;
        }
    }

    &:nth-child(2){
        flex:2.5;
        @media only screen and (max-width: 1100px){
            flex: 2;
        }
    }

    &:nth-child(3){
        flex: 1.5;
        @media only screen and (max-width: 1200px){
            display: none;
        }
    }


    &:nth-child(4){
        width: 60px;
    }

`;