import React, { useContext, useEffect, useState } from "react";
import LoaderCard from "../../components/loading-cards/loading-card";
import PopupForm from "../../components/popup-form/popup-form";
import { useLocation } from "react-router-dom";
import { toast } from 'react-toastify'
import { ApiContext } from "../../context/apiContext";
import { SocketContext } from "../../context/socketContext";
import {
    DocumentHeader,
    Title,
    ContentSection,
    LeaseBox,
    LeaseTitle,
    StatusBadge
} from './leases.pages.styles'

const Leases = ({headerHeight}) => {
    const location = useLocation();
    const [leases, setLeases] = useState([])
    const [showPopupUrl, setShowPopupUrl] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const api = useContext(ApiContext)
    const { subscribeToSignForm } = useContext(SocketContext);

    useEffect(() => {
        // Subscribe to new sign messages
        const unsubscribe = subscribeToSignForm((data) => {
            if (showPopupUrl.id === data.entity_id) {
                setShowPopupUrl(null);
            }
        });

        return () => unsubscribe(); // Cleanup the subscription on unmount
    },[subscribeToSignForm]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await api.get('api/lease/all')
                setLeases(data)
            } catch (error) {
                // Handle network or other errors
                toast.error(`There was an error loading the data: ${error.message}`);
            } finally {
                setTimeout(() => {
                    setIsLoading(false); // End loading
                }, 100);
            }
        }
        fetchData();
    },[])

    const handleClick = async (lease) => {
        const baseUrl = window.location.hostname === 'localhost' ? 'http://localhost:8000' : window.location.origin;
        if(lease.landlordStatus === "Pending"){
            toast.info('Generating Lease Document. Please hold for a few seconds');
            try {
                const data = await api.get(`/api/lease/landlord_review`, {id: lease.leaseId})
                if(data){
                    setShowPopupUrl({
                        value: `${baseUrl}/media/${data.path}`,
                        id: lease.leaseId,
                        docType: lease.name,
                        toSign: true
                    })
                }
            }catch(error){
                toast.error(`Failed to get Lease document: ${error.message}`);
            }
        }else{
            setShowPopupUrl({
                value: `${baseUrl}/media/${lease.fileUrl}`,
                id: lease.leaseId,
                docType: lease.name,
                toSign: false
            })
        }
    }

    const clickSign = async () => {
        try {
            const data = await api.get('api/lease/landlord_sign', {id: showPopupUrl.id})
            const baseUrl = window.location.hostname === 'localhost' ? 'http://localhost:8000' : window.location.origin;
            setShowPopupUrl(prev => ({
                ...prev,
                value: `${baseUrl}/media/${data.path}`
            })); 
            setLeases(currentLeases => currentLeases.map(lease => 
                lease.leaseId === showPopupUrl.id ? 
                {
                    ...lease,
                    landlordStatus: 'Completed',
                    fileUrl: data.path
                }
                :
                lease
            ))
        }catch(error){
            toast.error('Could not affix signatures on document')
        }
    }
  

    return (
        <>
            <DocumentHeader>
                <Title>All Leases</Title>
            </DocumentHeader>
            {
                isLoading?
                    <ContentSection>
                        <LoaderCard size = "small"/>
                    </ContentSection>
                    :
                    <ContentSection  headerHeight = {headerHeight}>
                        {
                            leases.map((lease, index) => 
                                <LeaseBox key = {index} onClick = {() => handleClick(lease)}>
                                    <LeaseTitle>{lease.name}</LeaseTitle>
                                    <StatusBadge status = {lease.landlordStatus}>{lease.landlordStatus}</StatusBadge>
                                </LeaseBox>
                            )
                        }
                    </ContentSection>
            }
            {
                showPopupUrl?.value  &&
                <PopupForm
                    showPopupUrl={showPopupUrl.value}
                    handleClose={() => 
                            setShowPopupUrl(null)
                    }
                    docType={showPopupUrl.docType}
                    toSign={showPopupUrl.toSign}
                    handleSign={clickSign}
                />
            }
        </>
    )
}

export default Leases