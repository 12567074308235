import React, { useState } from 'react'
import { SectionTitle, Label, GapDiv, Section, NextButton, PreviousButton } from '../shared-styles'


const DocumentHandling = ({applicationData, handleChange, setIsFocus}) => {
    const [isDirectUpload, setIsDirectUpload] = useState(applicationData?.isDirectUpload || false)

    const handleNext = () => {
        handleChange("isDirectUpload", isDirectUpload);
        if(isDirectUpload){
            setIsFocus('Package')
        }else{
            setIsFocus('Applicants')
        }
    }

    return (
        <Section>
            <SectionTitle>How would you like to handle the application?</SectionTitle>
            <GapDiv style={{flexDirection:'column'}}>
                <Label style = {{color: '#2C2C2C', display:'flex', alignItems:'flex-start', gap:'5px'}}>
                    <input
                        type="radio"
                        value={false}
                        name="directUpload"
                        checked={isDirectUpload === false} 
                        onChange={(e) => setIsDirectUpload(false)}
                    />
                    The applicants will fill their application form and upload their own documents
                </Label>
                <Label style = {{color: '#2C2C2C', display:'flex', alignItems:'flex-start', gap:'5px'}}>
                    <input
                        type="radio"
                        value={true}
                        name="directUpload"
                        checked={isDirectUpload === true} 
                        onChange={(e) => setIsDirectUpload(true)}
                    />
                    I will upload the application package on behalf of the applicants
                </Label>
            </GapDiv>
            <br/>
            <GapDiv>
                <PreviousButton onClick={()=>setIsFocus('Property Details')}>Previous</PreviousButton>
                <NextButton onClick={handleNext}>{isDirectUpload === true? "Add applicants & upload package":"Add applicants"}</NextButton>
            </GapDiv>
            
        </Section>
    )
}

export default DocumentHandling