import React, { useContext, useState } from "react";
import { ApiContext } from "../../../context/apiContext.js";
import BasicInfo from './basic-info/basic-info.jsx'
import Package from "./package/package.jsx";
import InternalNotes from "./internal-notes/internal-notes.jsx";
import ChatChannel from "../../agent-chat/agent-chat.jsx";
import RejectionModal from "../../reject-modal/reject-modal.jsx";
import { formatDate } from '../../../utilities/utility';
import { ReactComponent as Download } from '../../../assets/Download.svg'

import {
    Tab,
    TabHolder,
    Content,
    SubmittedDate,
    TabContentHeader
} from './review-modality.styles'

import {
    ApplicationPackageDiv
} from './../application-modality.styles'

import {
    ButtonDiv,
    PreviousButton,
    NextButton,
    Container
} from '../shared-styles.js'
import { UserContext } from "../../../context/userContext.js";

const ReviewComponent = ({focusApplication, setActiveTab, updateApplication, setFmrPaidModal}) => {

    const [focusTab, setFocusTab] = useState('Basic Info');
    const [rejectModal, setRejectModal] = useState(false);
    const submittedDate  = formatDate(new Date(focusApplication?.submittedTimeStamp)).formattedDate;
    const submittedTime = formatDate(new Date(focusApplication?.submittedTimeStamp)).formattedTime;
    const { user } = useContext(UserContext)
    const api = useContext(ApiContext)

    const [isLoading, setIsLoading] = useState(false);


    // const [triggerDownload, setTriggerDownload] = useState(false)

    const triggerDownload = async () => {
        setIsLoading(true)
        try {
            const response = await api.post('/api/application/combine-documents',{applicationId: focusApplication.id})

            const fileURL = window.location.origin + '/media/'+  response.file;
            const fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'download.pdf'); // Specify the file name and extension
            document.body.appendChild(fileLink);

            fileLink.click();

            window.URL.revokeObjectURL(fileURL); // Clean up: revoke the blob URL
            fileLink.remove(); // Remove the temporary link element
        } catch (error) {
            console.error('Error downloading the file:', error);
            // Handle errors if the request fails
        }finally{
            setIsLoading(false)
        }
    };
    

    return (
        <Container height={180}>
            <div style={{height: focusApplication.status === 'Rejected'? '100%': 'calc(100% - 60px)'}}>
                <TabContentHeader>    
                    <TabHolder>
                        <Tab onClick={() => setFocusTab('Basic Info')} active={focusTab === 'Basic Info'}>Basic Info</Tab>
                        <Tab onClick={() => setFocusTab('Package')} active={focusTab === 'Package'}>App Package</Tab>
                        {
                            focusApplication.status !== "Pending" &&
                            <>
                                <Tab onClick={() => setFocusTab('Internal Notes')} active={focusTab === 'Internal Notes'}>Team Discussion</Tab>
                                <Tab onClick={() => setFocusTab('Chat')} active={focusTab === 'Chat'}>Agent Chat</Tab>
                            </>
                        }
                    </TabHolder>
                    <div style={{display:'flex', gap: '10px', alignItems:'center'}}>
                        {
                            focusTab === 'Package' && <ApplicationPackageDiv onClick={() => triggerDownload()}>Download <Download/></ApplicationPackageDiv>
                        }
                        <SubmittedDate>Submitted On: {submittedDate} {submittedTime}</SubmittedDate>
                    </div>
                </TabContentHeader>
                <Content>
                    {
                        focusTab === 'Basic Info' && 
                        <BasicInfo 
                            agent = {focusApplication.agent}
                            applicants = {focusApplication.applicants}
                            setFocusTab = {setFocusTab}
                        />
                    }
                    {
                        focusTab === 'Package' && 
                        <Package 
                            applicants = {focusApplication.applicants} 
                            applicationPackage={focusApplication?.applicationPackage}
                            setActiveTab={setActiveTab}
                            applicationId = {focusApplication.id}
                            isLoading={isLoading}
                        />
                    }
                    {
                        focusTab === 'Internal Notes' && 
                        <InternalNotes 
                            groupId = {focusApplication.internalGroupId}
                        />
                    }
                    {
                        focusTab === 'Chat' && 
                        <ChatChannel 
                            groupId = {focusApplication.externalGroupId}
                        />
                    }
                </Content>
            </div>
            <ButtonDiv>
                    {
                        user.userType !== 'view_only' && focusApplication.status !== "Rejected" && focusApplication.status !== "Approved" &&
                            <PreviousButton onClick={() => setRejectModal(true)}>Reject</PreviousButton>
                    }
                    {
                        focusApplication.status !== "Under Review" && focusApplication.status !== "Rejected" && user.userType === "leasing_coordinator" &&
                        focusApplication.status !== "Approved" &&
                        <NextButton onClick={() => setActiveTab('Assign')}>Assign</NextButton>
                    }
                    {
                        focusApplication.status !== "Rejected" && user.userType === "admin" &&  focusApplication.status !== "Approved" &&
                        <NextButton onClick={() => setActiveTab('Approve')}>Approve</NextButton>
                    }
                    {
                        user.userType !== 'view_only' && focusApplication.status === "Approved" && !focusApplication.depositPaid &&
                        <NextButton onClick={() => setFmrPaidModal(true)}>Add Deposit & Create Deal</NextButton>
                    }
            </ButtonDiv>
            {
                rejectModal &&
                <RejectionModal
                    onClose = {() => setRejectModal(false)}
                    applicationId = {focusApplication.id}
                    updateApplication = {updateApplication}
                />
            }
        </Container>
    )
}

export default ReviewComponent