import React, { useState, useEffect } from "react";
import BrokerForm from './broker-form/broker-form';
import PropertyForm from "./property-form/property-form";
import AddApplicants from "./applicant-form/applicant-form";
import ReviewApplication from "./review-application/review-application";
import ApplicationAcknowledgment from "./complete-application/complete-application";
import DocumentHandling from "./document-handling/document-handling";
import AddApplicantPlusPackage from "./add-applicant-package/add-applicant-package";
import {
    Canvas,
    Title,
    AppointmentContainer,
} from './agent-application-form.styles'



const ApplicationForm = ({property}) => {
    const [finalId, setFinalId] = useState(null);
    const [isFocus, setIsFocus] = useState('Broker');//Broker
    const [applicationData, setApplicationData] = useState({
        agentName: '',
        agentEmail: '',
        agentPhone: '',
        agentId: '',
        brokerageName: '',
        propertyId: property.id,
        applicants: [],
        requestedMoveOut: null,
        requestedMoveIn: null,
        offerPrice: property.price,
        applicationPackage: null,
        isDirectUpload: false,
        moveinDeposits: {
            ...property.moveinDeposits,
            "Deposit On Account": 0
        }
    });

    const handleChange = (name, value) => {
        setApplicationData({ ...applicationData, [name]: value });
    };

    // Scroll to the top of the page when isFocus changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [isFocus]);


    return (
        <Canvas>
            { isFocus !== 'Submitted' && <Title>Application Request</Title>}
            <AppointmentContainer submitted = {isFocus === 'Submitted'}>
            {
                isFocus === 'Broker' ?
                <BrokerForm
                    applicationData={applicationData}
                    handleChange={handleChange}
                    setIsFocus={setIsFocus}
                    setApplicationData={setApplicationData}
                />
                :
                isFocus === 'Property Details' ?
                <PropertyForm
                    property={ property }
                    applicationData = {applicationData}
                    handleChange={handleChange}
                    setIsFocus={setIsFocus}
                />
                :
                isFocus === "Document Handling" ?
                <DocumentHandling
                    applicationData={applicationData}
                    handleChange={handleChange}
                    setIsFocus={setIsFocus}
                />
                :
                isFocus === "Package"?
                <AddApplicantPlusPackage
                    applicationData={applicationData}
                    setApplicationData = {setApplicationData}
                    setIsFocus={setIsFocus}
                />
                :
                isFocus === 'Applicants' ?
                <AddApplicants
                    applicationDataApplicants = {applicationData.applicants}
                    minAppCount = {property.bedrooms}
                    setApplicationData = {setApplicationData}
                    setIsFocus={setIsFocus}
                />
                :
                isFocus === 'Review' ?
                <ReviewApplication
                    property={ property }
                    applicationData = {applicationData}
                    setIsFocus={setIsFocus}
                    setFinalId = {setFinalId}
                />
                :
                isFocus === 'Submitted' &&
                <ApplicationAcknowledgment
                    agentEmail={applicationData.agentEmail}
                    managementEmail={property.managementEmail}
                    finalId = {finalId}
                />
            }
            </AppointmentContainer>
        </Canvas>
    )
}

export default ApplicationForm