import styled from "styled-components";

export const Label = styled.label`
    color: #A4A4A4;
    font-size: 14px;
    font-weight: 400;
`;

export const Currency = styled.div`
    font-size: 10px;
    color: #2C2C2C;
`;

export const WrapDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

export const Heading = styled.div` // Changed to h3 for semantic HTML, adjust as necessary
    color: #2C2C2C;
    font-size: 16px;
    font-weight: 500;
    // width: -webkit-fill-available;
    // min-width: 150px;
`;

export const FlexDiv = styled.div`
    display: flex;
    gap: 10px;
    position: relative;
    align-items: center;
    @media only screen and (max-width: 500px){
        flex-direction: column;
        align-items: unset;
    }
`;

export const Button = styled.div`
    cursor: pointer;
    border-radius: 56px;
    background: var(--Light-Blue, #F0F8FF);
    display: flex;
    text-align: center;
    padding: 4px 10px;
    min-width: 100px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    color: var(--Blue-100, #064274);
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    /* line-height: 20px; */
`;

export const DropdownContent = styled.div`
  position: absolute;
  z-index: 1;
  border-radius: 7px;
  border: 1px solid var(--F7, #F7F7F7);
  background: var(--White, #FFF);
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.25);
  left: 0px;
  top: 30px;
  width: -webkit-fill-available;

  border-radius: 10px;
`;

export const DropdownItem = styled.div`
    padding: 5px 10px;
    cursor: pointer;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--Greyish-Black, #323232);
    font-size: 14px;
    color: var(--Grey, #747474);
    leading-trim: both;
    border-radius: 7px;

    &:hover {
        background-color: #f0f0f0;
    }   
`;