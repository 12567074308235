import styled from "styled-components";

export const DocumentHeader = styled.div`
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding: 20px;
    gap: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 1;
`;

export const Title = styled.label`
    color: var(--Black, #1C1C1C);

    /* Title/Large */
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const ContentSection = styled.div`
    padding: 20px 20px 25px 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    justify-content: flex-start;
    align-content: flex-start;
    height: ${props => `calc(100vh - ${props.headerHeight}px)`};
`;

export const LeaseBox = styled.div`
    padding: 15px;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
    display: flex;
    width: 100%;
    border: 1px solid #ddd;
    align-items: center;
    /* max-width: 350px; */
    border-radius: 10px;
    height: fit-content;
    gap: 20px;
    justify-content: space-between;
    cursor: pointer;
`;

export const LeaseTitle = styled.div`
    font-size: 16px;
    color: #323232;
    margin: 0;
    font-weight: 500;

    @media only screen and (max-width: 800px){
        font-size: 14px;
    }
`;

const color = ({status}) => {
    if(status === 'Pending'){
        return `    
            background-color: #FFD1D1; // soft red
            color: #D8000C; // darker red
        `;
    }
    else if (status === 'Completed'){
        return `
            background-color: #E3FFE6;
            color: #1CCB00;
        `; 
    }
}

export const StatusBadge = styled.div`
    ${color}
    border-radius: 15px; // Fully rounded corners
    padding: 5px 10px; // Vertical and horizontal padding
    font-size: 12px; // Font size
    font-weight: 500; // Medium font weight
    user-select: none; // Prevent text selection
    display: inline-flex; // Use inline-flex to center content
    align-items: center; // Vertical align to center
    width: fit-content;
    justify-content: center; // Horizontal align to center
    text-edge: cap;

    @media only screen and (max-width: 800px){
        font-size: 10px;
    }
`;
