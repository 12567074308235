import React, { useContext, useEffect, useState } from "react";
import PropertySidePanel from "../../components/property-side-panel/property-side-panel";
import AgentApplicationStatus from "../../components/agent-application-status/agent-application-status";
import  { useParams } from 'react-router-dom'
import { SocketContext } from "../../context/socketContext";

import {
    Canvas
} from './agent-application-dashboard.styles'

const AgentApplicationDashboard = () => {

    const [applicationData, setApplicationData] = useState(null);
    const [agentId, setAgentId] = useState(null)
    const {applicationId} = useParams();
    const { authSocket, isSocketReady } = useContext(SocketContext) 

    useEffect(() => {
        const fetchData = async () => {
            console.log('fetching new data')
            let agentPrompt;

            // Retrieve the agent ID from session storage
            const storedAgentId = sessionStorage.getItem("authenticatedAgentId");

            if (storedAgentId ) {
                // You could also retrieve a stored agent ID from context or localStorage if needed
                agentPrompt = storedAgentId; 
            } else {
                agentPrompt = prompt('Please enter your Real estate ID to continue:');
                if (!agentPrompt) {
                    console.error("No agent ID provided.");
                    return; // Stop the execution if no agent ID is provided
                }
                sessionStorage.setItem("authenticatedAgentId", agentPrompt); 
            }

            const queryParams = new URLSearchParams({
                applicationId: applicationId,
                agentId: agentPrompt
            })
            try {
                let response = await fetch(`/api/application/agentdetail?${queryParams.toString()}`); // ToDO: ChANGE API BACK
                if (response.ok) {                    
                    let data = await response.json();
                    setAgentId(agentPrompt);
                    setApplicationData(data);
                } else if (response.status === 401 || response.status === 403) {
                    // Handle unauthorized or invalid agent ID
                    alert("Invalid or unauthorized Agent ID. Please try again.");
                    sessionStorage.removeItem("authenticatedAgentId"); // Clear the invalid ID from session storage
                } else {
                    // Handle errors, e.g., response status not OK
                    console.error("Failed to fetch properties:", response.status);
                }
            } catch (error) {
                // Handle network errors or other fetch issues
                console.error("Failed to fetch properties:", error);
            }
        };

        if(applicationId){
            fetchData();
        }
        
    },[applicationId])

    // Effect to handle socket authentication when socket is ready and accessToken is available
    useEffect(() => {
        if (isSocketReady && agentId) {
            authSocket(agentId, null);
        }
    }, [isSocketReady, agentId, authSocket]);

    useEffect(() => {
        // Clear session storage when the window/tab is closed
        const handleUnload = () => {
            sessionStorage.removeItem("authenticatedAgentId");
        };
    
        // Attach event listener to window unload
        window.addEventListener('beforeunload', handleUnload);
    
        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, []);

    if (!applicationData){
        return null
    }

    return (
        <Canvas>
            <PropertySidePanel property = {applicationData.property} background = {'#FFFFFF'}/>
            <AgentApplicationStatus
                applicationData = {applicationData}
                setApplicationData = {setApplicationData}
                agentId = {agentId}
            />
        </Canvas>
    )
}

export default AgentApplicationDashboard