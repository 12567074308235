import React, { useContext, useState } from 'react'
import PaymentDealTab from '../../components/payment-deal-tab/payment-deal-tab'
import PaymentAgentTab from '../../components/payment-agent-tab/payment-agent-tab'
import { UserContext } from '../../context/userContext'

import {
    PropertyHeader, Title, ContentSection, TabHolder, Tab, TabContentHeader
} from './payments.pages.styles'

const Payments = ({headerHeight}) => {
    const {user} = useContext(UserContext)
    const [focusTab, setFocusTab] = useState('Deal');

    return (
        <>
            <PropertyHeader>
                <Title>Payments</Title>
                <TabContentHeader>    
                    <TabHolder>
                        {
                            user?.clientType === "property_management" &&
                            <>
                                <Tab 
                                    onClick = {()=>setFocusTab('Deal')} active = {focusTab === "Deal"}
                                >
                                    Deal Summary
                                </Tab>
                                <Tab 
                                    onClick = {()=>setFocusTab('Agent')} active = {focusTab === "Agent"}
                                >
                                    Agent Comission Overview
                                </Tab>
                            </>
                        }
                        
                    </TabHolder>
                </TabContentHeader>
            </PropertyHeader>
            <ContentSection  headerHeight = {headerHeight}>
                {
                    focusTab === "Deal" &&
                    <PaymentDealTab/>
                }
                {
                    focusTab === "Agent" &&
                    <PaymentAgentTab/>
                }
            </ContentSection>
            
        </>
    )
}

export default Payments