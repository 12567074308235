import React, { useState, useEffect } from "react";
import FormInput from "../../form-input/form-input";
import DropdownSearch from "../../dropdown-search/dropdown-search";
import {
    Section,
    SectionTitle,
    NextButton,
    Heading
} from '../shared-styles'

const BrokerForm = ({applicationData, handleChange, setIsFocus, setApplicationData }) => {
    const [errors, setErrors] = useState({})

    // Load form data from local storage when the component mounts
    useEffect(() => {
        const savedFormData = localStorage.getItem('agentFormData');
        if (savedFormData) {
            const parsedData = JSON.parse(savedFormData);
            console.log(parsedData)
            // console.log("Loaded from localStorage:", parsedData);
            setApplicationData(prev => ({
                ...prev,
                ...parsedData
            }))
        }
    }, []);

    const validateAgentInputs = () => {
        let newErrors = {};
        if (!applicationData.agentName) newErrors.agentName = 'Name is required';
        if (!applicationData.brokerageName) newErrors.brokerageName = 'Brokerage is required';
        // if (!applicationData.agentId) newErrors.agentId = 'License is required';

        const agentIdRegex = /^[0-9]*$/
        if (!applicationData.agentId) {
            newErrors.agentId = 'License is required';
        } else if (!agentIdRegex.test(applicationData.agentId)){
            newErrors.agentId = 'Real Estate License is incorrect';
        }

         // Email validation
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!applicationData.agentEmail) {
            newErrors.agentEmail = 'Email is required';
        } else if (!emailRegex.test(applicationData.agentEmail)) {
            newErrors.agentEmail = 'Enter a valid email';
        }

        // Phone validation
        const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (!applicationData.agentPhone) {
            newErrors.agentPhone = 'Phone is required';
        } else if (!phoneRegex.test(applicationData.agentPhone)) {
            newErrors.agentPhone = 'Enter a valid phone number';
        }
        // if (!formData.guarantor) newErrors.guarantor = 'Select guarantor type';
        // if (!formData.clientType) newErrors.clientType = 'Select client type';
        // Add other validation rules here
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleNext = () => {
        if (validateAgentInputs()) {
            //Save agent information to localstorage for future usage. Updates every time!
            localStorage.setItem('agentFormData', JSON.stringify({
                agentName: applicationData.agentName,
                agentPhone: applicationData.agentPhone,
                agentEmail: applicationData.agentEmail,
                agentId: applicationData.agentId,
                brokerageName: applicationData.brokerageName
            }));
            setIsFocus('Property Details')
        }
    }

    return (
        <>
            <SectionTitle>Agent Details</SectionTitle>
            <Heading>Please enter your correct details, and updated RE license. This is how we track your commission payments.</Heading>
            <Section>
                <FormInput
                    label={'Name'}
                    isRequired={true}
                    type={'text'}
                    handleChange={(value) => handleChange('agentName', value)}
                    value = {applicationData.agentName}
                    defaultValue={applicationData.agentName}
                    error={errors.agentName}
                />
                <FormInput
                    label={'Email'}
                    isRequired={true}
                    type={'email'}
                    handleChange={(value) => handleChange('agentEmail', value)}
                    value = {applicationData.agentEmail}
                    defaultValue={applicationData.agentEmail}
                    error={errors.agentEmail}
                />
                <FormInput
                    label={'Phone'}
                    isRequired={true}
                    type={'phone'}
                    handleChange={(value) => handleChange('agentPhone', value)}
                    value = {applicationData.agentPhone}
                    defaultValue={applicationData.agentPhone}
                    error={errors.agentPhone}
                />
                <FormInput
                    label={'Real-estate License'}
                    isRequired={true}
                    type={'text'}
                    handleChange={(value) => handleChange('agentId', value)}
                    value = {applicationData.agentId}
                    defaultValue={applicationData.agentId}
                    error={errors.agentId}
                />
                {/* <FormInput
                    label={'Brokerage Name'}
                    isRequired={true}
                    type={'text'}
                    handleChange={(value) => handleChange('brokerageName', value)}
                    value = {applicationData.brokerageName}
                    defaultValue={applicationData.brokerageName}
                    error={errors.brokerageName}
                /> */}
                <DropdownSearch
                    handleChange={(value) => handleChange('brokerageName', value)}
                    label={'Brokerage Name'}
                    isRequired={true}
                    error={errors.brokerageName}
                    defaultValue={applicationData.brokerageName}
                />
            </Section>
            <NextButton onClick={() => handleNext()}>Select Property Lease Terms</NextButton>
        </>
    )
}

export default BrokerForm