import React, { useState, createContext, useCallback, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify'
// Create a context
export const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [user, setUser] = useState(null); // Initialize the user state
    const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken')); // Optionally initialize from storage
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken')); // Optionally initialize from storage

    // console.log(user)

    // Function to log in the user
    const login = useCallback(async (username, password) => {
        try {
            const response = await fetch('/api/user/token/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            if (!response.ok) {
                throw new Error('Login failed');
            }

            const data = await response.json();
            setUser(data); // Update user state
            setAccessToken(data.access); // Update access token state
            setRefreshToken(data.refresh); // Update refresh token state

            // Optionally save tokens to local storage to persist login state
            localStorage.setItem('accessToken', data.access);
            localStorage.setItem('refreshToken', data.refresh);

            if(data.userType === "landlord"){
                navigate('/leases')
            }else{
                navigate('/showings')
            }
        } catch (error) {
            // toast.error(`Login error: ${error}`);
            // Handle error, such as showing an error message to the user
            return false
        }
    }, []);

    const logout = () => {
        setUser({});
        setAccessToken();
        setRefreshToken();
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        navigate('/');
    }

    const refreshTokens = useCallback(async () => {
        try {
            const response = await fetch('/api/user/token/refresh/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Cookie': 'csrftoken=...' // If CSRF token is needed, ensure it's handled securely
                },
                body: JSON.stringify({ refresh: refreshToken }),
            });

            if (!response.ok) {
                // throw new Error('Token refresh failed');
                logout();
            }

            const data = await response.json();
            setAccessToken(data.access); // Update access token state
            setRefreshToken(data.refresh); // Update refresh token state

            // Optionally save new tokens to local storage to persist state
            localStorage.setItem('accessToken', data.access);
            localStorage.setItem('refreshToken', data.refresh);
        } catch (error) {
            toast.error(`Refresh token error:  ${error}`);
            logout();
        }
    }, [refreshToken])

    const verifyToken = async () => {
        if (!accessToken) navigate('/');

        // Assuming you have an endpoint to verify the token
        if (accessToken) {
            try {
                const response = await fetch('/api/user/profile_details/', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                if (response.ok) {
                    const userData = await response.json();
                    // console.log(userData)
                    // console.log('here')
                    setUser(userData);

                    // setAccessToken(userData.access); // Update access token state
                    // setRefreshToken(userData.refresh); // Update refresh token state
                    if (location.pathname === '/'){
                        if(userData.userType === "landlord"){
                            navigate('/leases')
                        }else{
                            navigate('/showings')
                        }
                    }
                    return true
                } else {
                    logout();
                }
            } catch (error) {
                toast.error(`Error verifying token: ${error}`);
            }
        }
    };

    const resetPassword = async (data) => {
        // console.log(data)
        try {
            const response = await fetch('/api/user/reset_password/', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(data)
            });
            if (response.ok) {
                const userData = await response.json();
                // console.log(userData)
                // console.log('here')
                setUser(userData);        
                toast.success('The password has successfully been changed')
                return true;
            } else {
                throw new Error('Could not reset password')
            }
        } catch (error) {
            toast.error(`Error verifying token: ${error}`);
        }
    }
    

    // The value that will be given to the context
    const value = {
        user,
        accessToken,
        login,
        refreshTokens, // Expose the refreshTokens function
        verifyToken,
        resetPassword,
        logout
    };

    // console.log(user)

    useEffect(() => {
        const interval = setInterval(() => {
            refreshTokens();
        }, 1000 * 60 * 60); // e.g., refresh every hour
        return () => clearInterval(interval);
    }, []);

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};
