// StyledHeader.js
import styled from 'styled-components';

export const HeaderContainer = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px; // Adjust padding to match your design
    background-color: #fff; // Set the background color as needed
    border-bottom: 1px solid #F1F1F1;
    @media only screen and (max-width: 800px){
        gap: 10px;
    }
`;

export const Greeting = styled.div`
    font-size: 24px;
    color: #333; // Adjust the color as needed
    display: flex;
    align-items: center;

    // If you're using an emoji or an image:
    .emoji {
        margin-left: 10px; // Adjust spacing based on your design
    }

    @media only screen and (max-width: 800px){
        font-size: 20px;
    }
`;

export const Blip = styled.div`
top: -5px;
    height: 12px;
    position: absolute;
    background: red;
    display: flex;
    right: -5px;
    padding: 3px;
    font-weight: 600;
    font-size: 11px;
    color: white;
    border-radius: 12px;
    aspect-ratio: 1 / 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: bottom;
`;

export const UserSection = styled.div`
    display: flex;
    align-items: center;
    gap:20px;
`;

export const Icon = styled.div`
    // margin: 0 5px; // Adjust spacing between icons
    cursor: pointer;
    display: flex; 
    position: relative;
`;

export const ProfilePicture = styled.img`
    width: 40px; // Adjust the size as needed
    height: 40px;
    border-radius: 100%; // Circle shape
    object-fit: cover; // Maintain aspect ratio
`;
