import React, { useState } from "react";
import { toast } from "react-toastify";
import { formatCurrency } from "../../utilities/utility";

import { 
    ModalOverlay, ModalWrapper, Title, SpaceDiv, FlexDiv, CloseButton, AttachmentBox, RemoveButton,
    FileUploadLabel, RoundDiv, HiddenFileInput, UploadInstruction,
    ConfirmButton
} from "./add-invoice-w9.styles";
import { ReactComponent as Document } from '../../assets/document-upload.svg'


const AddInvoiceAndW9 = ({deals, agentId, setAgentDetails, onClose}) => {
    const [w9State, setw9State] = useState({
        attachment: null,
        previewUrl: null
    });
    const [invoiceState, setInvoiceState] = useState({
        attachment: null,
        previewUrl: null
    });
    const [errors, setErrors] = useState({});

    const onW9Remove = () => {
        setw9State({
            previewUrl: null,
            attachment: null
        });
    };

    const handleW9Upload = (event) => {
        const file = event.target.files[0];
        if (file && file.type.substr(0, 5) === 'image') {
            const reader = new FileReader();
            reader.onloadend = () => {
                setw9State((prevState) => ({
                    previewUrl: reader.result,
                    attachment: file
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const onInvoiceRemove = () => {
        setInvoiceState({
            previewUrl: null,
            attachment: null
        });
    };

    const handleInvoiceUpload = (event) => {
        const file = event.target.files[0];
        if (file && file.type.substr(0, 5) === 'image') {
            const reader = new FileReader();
            reader.onloadend = () => {
                setInvoiceState((prevState) => ({
                    previewUrl: reader.result,
                    attachment: file
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const validateInputs = () => {
        let newErrors = {}
        if (!w9State.attachment) newErrors.w9 = "w9 is required";
        if (!invoiceState.attachment) newErrors.invoice = "Invoice is required";
        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    }


    const generateInvoice = async () => {
        if(validateInputs()){
            const formData = new FormData();
            //send subdomain
            const { hostname } = window.location;
            const subdomain = hostname.split('.')[0];
            formData.append('company', subdomain);

            // Append each deal's dealId individually
            deals.forEach(deal => {formData.append('deals[]', deal.dealId);});
            formData.append('agentId', agentId);
            formData.append('w9', w9State.attachment);
            formData.append('invoice', invoiceState.attachment);

            try {
                const response = await fetch(`/api/payment/agent_submit_invoice`, {
                    method: 'PUT', // Specify the HTTP method
                    body: formData
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
        
                const data = await response.json(); // Parse the JSON response
                setAgentDetails(data); 
                toast.info("Invoice generated & sent");
                onClose();
            } catch (error) {
                toast.error("Error generating invoice:", error.message);
            }
        }
    }

    return (
        <ModalOverlay onClick={onClose}>
            <ModalWrapper
                onClick={(e) => e.stopPropagation()}
            >
                <SpaceDiv>
                    <Title>Add Invoice And W9</Title>
                    <FlexDiv>
                        <span style={{fontSize:"14px"}}>Close</span>
                        <CloseButton onClick={onClose}>X</CloseButton>
                    </FlexDiv>
                </SpaceDiv>
                <div style={{fontWeight:700}}>Total Commission: {formatCurrency(deals.reduce((agg,deal) => agg+deal.commission,0))}</div>
                {
                    w9State.previewUrl ?
                    <AttachmentBox>
                        <img src = {w9State.previewUrl}/>
                        <RemoveButton onClick={onW9Remove}>×</RemoveButton>
                    </AttachmentBox>
                    :
                    <FileUploadLabel htmlFor="file-upload1">
                        <RoundDiv><Document /></RoundDiv>
                        <HiddenFileInput 
                            id="file-upload1" 
                            type="file" 
                            accept="image/*" 
                            onChange={handleW9Upload} 
                        />
                        <UploadInstruction>Click to Upload W9<br/>(Max. File size: 5 MB)</UploadInstruction>
                        {
                            errors.w9 &&
                            <span>{errors.w9}</span>
                        }
                    </FileUploadLabel>
                }
                                {
                    invoiceState.previewUrl ?
                    <AttachmentBox>
                        <img src = {invoiceState.previewUrl}/>
                        <RemoveButton onClick={onInvoiceRemove}>×</RemoveButton>
                    </AttachmentBox>
                    :
                    <FileUploadLabel htmlFor="file-upload2">
                        <RoundDiv><Document /></RoundDiv>
                        <HiddenFileInput 
                            id="file-upload2" 
                            type="file" 
                            accept="image/*" 
                            onChange={handleInvoiceUpload} 
                        />
                        <UploadInstruction>Click to Upload Invoice<br/>(Max. File size: 5 MB)</UploadInstruction>
                        {
                            errors.invoice &&
                            <span>{errors.invoice}</span>
                        }
                    </FileUploadLabel>
                }
                <ConfirmButton onClick={generateInvoice}>Upload Documents</ConfirmButton>
            </ModalWrapper> 
        </ModalOverlay>
    )
}

export default AddInvoiceAndW9