import styled, {css} from "styled-components";

export const Container = styled.div`
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

export const Section = styled(Container)`
    gap: 20px;
`;

export const TitleDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Heading = styled.div`
    color: var(--Greyish-Black, #323232);
    leading-trim: both;
    text-edge: cap;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const FlexDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const CloseButton = styled.button`
    cursor: pointer;
    border: none;
    background: #f7f7f7;
    width: 35px;
    padding: 8px;
    height: 35px;
    border-radius: 35px;
    &:hover {
        background-color: #f5f5f5; // A subtle hover effect
    }
`;

export const RadioGroup = styled.div`
    display: flex;
    align-items: center;
    // justify-content: space-between;
    font-size: 14px;
    gap: 15px;
`;

const selectedStyle = css`
    color: #E45A5C;
`;

export const RadioButton = styled.input`
    // opacity: 0; // Hide the default HTML radio button
    // position: fixed;
    // width: 0;
    accent-color: #E45A5C;
`;

export const RadioElement = styled.div`
    display: flex;
    gap: 2px;
    align-items: flex-start;
`;

export const RadioLabel = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #747474; // Default color
    ${({ isSelected }) => isSelected && selectedStyle}
`;



export const DocumentRow = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f1f1f1;
    padding: 10px 20px;
    align-items: baseline;

    &:last-child{
        border-bottom: unset;
    }
`;

export const DocumentType = styled.div`
    color: #2c2c2c;
    font-weight: 800;
    font-size: 10px;
    // color: ${props => props.documentStatus ? '#a4a4a4':'#FFA500'};

    &.title {
        font-weight: 500;
        color: var(--Greyish-Black, #323232);
        font-size: 12px;
    }

    &.total-title {
        font-weight: 600;
        color: #064274;
        font-size: 12px;
    }
`;

export const HeadingContainer = styled.div`
    display: flex;
    // gap: 10px;
    flex-direction: column;
`;

export const InvoiceTitle = styled.div`
    border-bottom: ${props => props.hideBorder? 'unset':'1px solid #ddd'};
    padding: 5px 0px;
    color: #064274;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const DocumentsContainer = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
`;

export const StatusBadge = styled.div`

    ${({ status }) => status && css`
        background-color: ${statusColors[status].background};
        color: ${statusColors[status].color};
    `}
    border-radius: 15px;
    padding: 2px 10px;
    font-size: 11px;
    font-weight: 600;
    user-select: none;
    width: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
`;

const statusColors = {
    "Completed": { background: '#E3FFE6', color: '#1CCB00' },
    "Pending": {background: '#FFD1D1', color: '#D8000C' },
};

export const Button = styled.button`
    font-size: 10px;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-weight: 600;
    color: white;
    width: 112px;
`;

export const DocumentButton = styled(Button)`
    background: #fff;
    color: #323232;
    width: fit-content;
`;

export const PreviewContainer = styled.div`
    display: flex;
    gap: 10px 20px;
    // border-top: 1px solid #ddd;
    padding:5px 20px 5px 10px;
    align-items: baseline;
    justify-content: space-between;
    background: aliceblue;
`;

export const FileUploadLabel = styled.label`
    border: 1px dashed var(--Blue-100, #064274);
    background: #F0F8FF;
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    transition: border-color 0.3s;
    border-radius: 10px;
    span {
        color: red;
        font-weight: 500;
    }
`;


export const HiddenFileInput = styled.input`
    display: none;
`;

export const UploadInstruction = styled.p`
    text-align: center;
    color: #747474;
    font-size: 14px;
    margin-top: 10px;
`;

export const RoundDiv = styled.div`
    border-radius: 50px;
    background: var(--White, #FFF);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
`;

export const RemoveButton = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background: rgba(0, 0, 0, 0.8);
    }
`;

export const AttachmentBox = styled.div`
    display: flex;
    padding: 10px;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    cursor: pointer;
    align-self: stretch;
    border: 1px solid var(--F1, #F1F1F1);
    border-radius: 7px;

    img {
        object-fit: cover;
        border-radius: 7px;
        max-height: 200px;
        width: 100%;
    }
`;

const buttonStyles = css`
    border: none;
    border-radius: 4px;
    padding: 12px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
`;

export const ConfirmButton = styled.button`
    ${buttonStyles}
    background-color: #064274;
     color: white;
     &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }
`;