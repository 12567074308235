import React, { useState, useEffect, useContext } from "react";
import ImageModal from "../image-modals/image-modals";
import { ApiContext } from "../../context/apiContext";
import { toast } from "react-toastify";
import { formatCurrency, toProperCase } from "../../utilities/utility";
import {
    Container, TitleDiv, FlexDiv, CloseButton, Heading, Section, RadioGroup, RadioButton, RadioElement, RadioLabel,
    PreviewContainer, DocumentRow, DocumentType, StatusBadge, HeadingContainer, InvoiceTitle, Button, DocumentButton,
    FileUploadLabel, RoundDiv, HiddenFileInput, UploadInstruction, AttachmentBox, RemoveButton, ConfirmButton
} from './commission-box.styles'
import { ReactComponent as Document } from '../../assets/document-upload.svg'


const CommissionBox = ({focusAgent, onClose, updateAgent}) => {
    const api = useContext(ApiContext)
    const [isFocus, setFocus] = useState('Invoiced Deals');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImageUrl, setModalImageUrl] = useState('');

    // Single state to manage file upload data and selected invoice
    const [uploadState, setUploadState] = useState({
        invoiceId: null, // Tracks the currently selected invoice ID
        previewUrl: null, // Stores the preview URL of the uploaded file
        attachment: null // Stores the actual file object
    });
    const [errors, setErrors] = useState({});

    useEffect(()=> {
        setErrors({})
        setUploadState({
            invoiceId: null,
            previewUrl: null,
            attachment: null
        });
    },[focusAgent])

    const handleUploadClick = (invoiceId) => {
        // If the same invoice is clicked, toggle it off, otherwise select a new invoice
        setUploadState((prevState) => ({
            ...prevState,
            invoiceId: prevState.invoiceId === invoiceId ? null : invoiceId,
            previewUrl: null, // Clear previous preview
            attachment: null // Clear previous attachment
        }));
    };


    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file && file.type.substr(0, 5) === 'image') {
            const reader = new FileReader();
            reader.onloadend = () => {
                setUploadState((prevState) => ({
                    ...prevState,
                    previewUrl: reader.result,
                    attachment: file
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const validateInputs = () => {
        let newErrors = {}
        if (!uploadState.attachment) newErrors.attachment = "Check Image is required";
        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    }

    const updatePayment = async (event) => {
        event.stopPropagation();
        if(validateInputs()){
            const formData = new FormData();
            formData.append('invoiceId', uploadState.invoiceId);
            formData.append('attachment', uploadState.attachment);

            try {
                const data = await api.put('/api/payment/landlord_clear_invoice', formData);
                // updateAgent({agentId: 1, invoiceId: 1, status: 'Paid', checkFile: 'ADDEDCHECK.pdf'});
                updateAgent(data)
                toast.success('Invoice Updated')
                setUploadState({ invoiceId: null, previewUrl: null, attachment: null });
            } catch (error) {
                toast.error('Error during the fetch operation:', error.message);
            } 
        }
    }

    const onRemove = () => {
        setUploadState((prevState) => ({
            ...prevState,
            previewUrl: null,
            attachment: null
        }));
    };

    const requestInvoice = () => {
        try {
            const data = api.post('api/payment/request_invoice', {agentId: focusAgent.agentId})
            toast.info('Invoice requested from the agent')
        }catch (error) {
            toast.error('Something didnt work like it was supposed to:', error.message);
        } 
    }

    const handleClose = (event) => {
        event.stopPropagation();
        onClose();
    }

    const openModal = (imageUrl) => {
        setModalImageUrl(imageUrl);
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
        setModalImageUrl('');
    }

    return (
        <>
            <Container>
                <Section>
                    <TitleDiv>
                        <Heading>Agent Commission Details</Heading>
                        <FlexDiv>
                            <span style={{fontSize:"14px"}}>Close</span>
                            <CloseButton onClick={handleClose}>X</CloseButton>
                        </FlexDiv>
                    </TitleDiv>
                    {/* <Heading>Choose an action</Heading> */}
                    <RadioGroup>
                    {['Invoiced Deals','Uninvoiced Deals'].map((action) => (
                        <RadioElement key={action}>
                            <RadioButton
                                type="radio"
                                id={action}
                                name="action"
                                value={action}
                                checked={isFocus === action}
                                onChange={() => setFocus(action)}
                            />
                            <RadioLabel htmlFor={action} isSelected={isFocus === action}>
                                {action}
                            </RadioLabel>
                        </RadioElement>
                    ))}
                    </RadioGroup>
                </Section>
                {
                    isFocus === "Invoiced Deals" ?
                    <Section>
                        {
                            focusAgent?.invoices.map((invoice)=> 
                                <HeadingContainer>
                                    <InvoiceTitle hideBorder = {uploadState?.invoiceId}>
                                        <FlexDiv>
                                            <div>{toProperCase(invoice.invoiceName)}</div>
                                            <StatusBadge status={invoice.status}>{invoice.status}</StatusBadge>
                                        </FlexDiv>
                                        {
                                            invoice.status === "Pending" &&
                                            <FlexDiv>
                                                <Button 
                                                    style = {{background: '#064274'}}
                                                    onClick={() => handleUploadClick(invoice.invoiceId)}
                                                >{uploadState.invoiceId === invoice.invoiceId ? 'Hide':'Add Check'}</Button>
                                            </FlexDiv>
                                        }                                 
                                    </InvoiceTitle>
                                    {
                                        uploadState?.invoiceId === invoice.invoiceId && (
                                            uploadState.previewUrl ?
                                            <AttachmentBox>
                                                <img src = {uploadState.previewUrl}/>
                                                <RemoveButton onClick={onRemove}>×</RemoveButton>
                                                <ConfirmButton onClick={updatePayment}>Update Payment</ConfirmButton>
                                            </AttachmentBox>
                                            :
                                            <FileUploadLabel htmlFor="file-upload">
                                                <RoundDiv><Document /></RoundDiv>
                                                <HiddenFileInput 
                                                    id="file-upload" 
                                                    type="file" 
                                                    accept="image/*" 
                                                    onChange={handleFileUpload} 
                                                />
                                                <UploadInstruction>Click to Upload<br/>(Max. File size: 5 MB)</UploadInstruction>
                                                {
                                                    errors.attachment &&
                                                    <span>{errors.attachment}</span>
                                                }
                                            </FileUploadLabel>
                                            )
                                    }
                                    {
                                        invoice.deals.map((deal) => 
                                            <DocumentRow key={deal.dealId}>
                                                <DocumentType className="title">{deal.propertyAddress}</DocumentType>
                                                <DocumentType className="title">{formatCurrency(deal.amount)}</DocumentType>
                                            </DocumentRow>
                                        )
                                    }
                                    <PreviewContainer>
                                        <FlexDiv>
                                            {
                                                invoice.invoiceFile && 
                                                <DocumentButton onClick={() => openModal(invoice.invoiceFile)}>Invoice</DocumentButton>
                                            }
                                            {
                                                invoice.w9File && 
                                                <DocumentButton onClick={() => openModal(invoice.w9File)}>W9</DocumentButton>
                                            }
                                            {
                                                invoice.checkFile && 
                                                <DocumentButton onClick={() => openModal(invoice.checkFile)}>Check</DocumentButton>
                                            }
                                        </FlexDiv>
                                        <DocumentType className="total-title">Total: {formatCurrency(invoice.totalAmount)}</DocumentType>
                                    </PreviewContainer>
                                </HeadingContainer>
                            )
                        }
                    </Section>
                    :
                    <Section>
                         <HeadingContainer>
                            <InvoiceTitle>
                                <div>Completed Deals</div>
                                <Button 
                                    onClick={requestInvoice}
                                    style = {{background: '#E45A5C'}}
                                >Request an invoice</Button>
                            </InvoiceTitle>
                            {
                                focusAgent?.uninvoicedDeals.filter(deal => deal.paymentStatus === "Completed").map(deal => 
                                    <>
                                        <DocumentRow>
                                            <DocumentType className="title">{deal.propertyAddress}</DocumentType>
                                            <DocumentType className="title">{formatCurrency(deal.commission)}</DocumentType>
                                        </DocumentRow>
                                    </>
                                )
                            }
                            <PreviewContainer style={{paddingLeft:'20px'}}>
                                <DocumentType className="total-title">Total Commission:</DocumentType>
                                <DocumentType className="total-title">
                                    {
                                        formatCurrency(focusAgent?.uninvoicedDeals.filter(deal => deal.paymentStatus === "Completed")
                                        .reduce((acc,deal) => acc + deal.commission,0))
                                    }
                                </DocumentType>
                            </PreviewContainer>
                        </HeadingContainer>
                        <HeadingContainer>
                            <InvoiceTitle>
                                <div>Deals with Payments Due:</div>
                            </InvoiceTitle>
                            {
                                focusAgent?.uninvoicedDeals.filter(deal => deal.paymentStatus !== "Completed").map(deal => 
                                    <>
                                        <DocumentRow>
                                            <DocumentType className="title">{deal.propertyAddress}</DocumentType>
                                            <DocumentType className="title">{formatCurrency(deal.commission)}</DocumentType>
                                        </DocumentRow>
                                    </>
                                )
                            }
                            <PreviewContainer style={{paddingLeft:'20px'}}>
                                <DocumentType className="total-title">Total Commission:</DocumentType>
                                <DocumentType className="total-title">
                                    {
                                        formatCurrency(focusAgent?.uninvoicedDeals.filter(deal => deal.paymentStatus === "Completed")
                                        .reduce((acc,deal) => acc + deal.commission,0))
                                    }
                                </DocumentType>
                            </PreviewContainer>
                        </HeadingContainer>
                    </Section>
                }
            </Container>
            {
                isModalOpen &&
                <ImageModal onClose={closeModal}>
                    <img src={modalImageUrl} alt="Zoomed Check" />
                </ImageModal>
            }
        </>
    )
} 

export default CommissionBox