import React, { useState, useRef, useEffect } from 'react';

import {
    FilterWrapper, 
    FilterButton,
    SearchInput,
    DropdownContent,
    OptionLabel,
    SelectionBox,
    FlexDiv,
    SubmitButton,
    ResetButton
} from './custom-multi-select.styles'

import { ReactComponent as Filter } from '../../assets/Table-Filter.svg'
import { ReactComponent as FilterSelected } from '../../assets/Table-Filter-Fill.svg'


const CustomMultiSelect = ({ options, selectedOptions, onChange, placeholder }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [internalOptions, setInternalOptions] = useState([...selectedOptions])
    const divRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                setIsOpen(false); // Close the dropdown if clicked outside
            }
        };

        // Add event listener when the component is mounted
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener when the component is unmounted
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []); // Empty dependency array means this runs only on mount and unmount

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleOptionChange = (value) => {
        if (internalOptions.includes(value)) {
            setInternalOptions(internalOptions.filter((option) => option !== value));
        } else {
            setInternalOptions([...internalOptions, value]);
        }
    };

    const handleSubmit = () => {
        onChange(internalOptions)
        toggleDropdown();
    }

    const handleReset = () => {
        setInternalOptions([]);
        onChange([]);
        toggleDropdown();
    }

    const filteredOptions = options.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <FilterWrapper ref = {divRef}>
            <FilterButton selected = {selectedOptions.length > 0} onClick={toggleDropdown}>
                {placeholder} {selectedOptions.length > 0 ? <FilterSelected/>:<Filter/>}
            </FilterButton>
            <DropdownContent show={isOpen}>
                <div>
                    <SearchInput
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <SelectionBox>
                    {
                        filteredOptions.map((option) => (
                            <OptionLabel key={option.value}>
                                <input
                                    type="checkbox"
                                    value={option.value}
                                    checked={internalOptions.includes(option.value)}
                                    onChange={() => handleOptionChange(option.value)}
                                />
                                {option.value}
                            </OptionLabel>
                        ))
                    }
                    </SelectionBox>    
                </div>
                <FlexDiv>
                    <ResetButton onClick={handleReset}>Reset</ResetButton>
                    <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
                </FlexDiv>
            </DropdownContent>
        </FilterWrapper>
    );
};

export default CustomMultiSelect;
