import styled, {css} from "styled-components";

export const Canvas = styled.div`
    display: flex;
    width: -webkit-fill-available;

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
`;

export const SidePanel = styled.div`
    min-width: 250px;
    width: 20%;
    padding: 50px;
    display: flex;
    gap: 10px;
    border-right: 3px solid #EEEEEE;
    flex-direction: column;

    @media screen and (max-width: 1000px) {
        padding: 20px;
    }

    @media screen and (max-width: 800px) {
        min-width: unset;
        padding: 20px;
        width: unset;
        border-right: unset;
        border-bottom: 2px solid #EEEEEE;
    }
`;

export const LogoStyled = styled.div`
    margin-bottom: 20px;
    width: 200px;

    @media screen and (max-width: 800px) {
        display: none;
    }
`;

export const AgentTitle = styled.div`
    overflow: hidden;
    color: #525252;
    text-overflow: ellipsis;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 33px */


    @media screen and (max-width: 800px){
        display: unset;
        font-size: 20px;
    }
`;

export const SummaryTitle = styled(AgentTitle)`
    font-size: 16px;
`;

export const SummaryDiv = styled.div`
    display: flex;
    flex-direction: column;
    // padding: 0px 5px;
    gap: 10px;

    @media screen and (max-width: 800px){
        display: none;
        
    }
`;

export const AgentKeyItem = styled.div`
    color: var(--Light-Grey, #A4A4A4);
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
`;

export const AgentItem = styled.div`
    color: #525252;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
`;

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1.5fr 1fr);
    gap: 15px;
    align-items: baseline;
    width: -webkit-fill-available;
`;

export const MainPanel = styled.div`
    padding: 40px 0px;
    gap: 30px;
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    overflow: auto;

    @media screen and (max-width: 800px) {
        padding: 20px 0px;
        width: unset;
        gap: 10px;
        height: -webkit-fill-available;
    }
`;


export const TabContentHeader = styled.div`
    display: flex;
    padding: 0px 20px;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    align-items: center;
    @media screen and (max-width: 800px) {
       justify-content: center;
       gap: 20px;
    }
`;

export const TabHolder = styled.div`
    gap: 15px;
    display: flex;
`;

export const Tab = styled.button`
    background: none;
    font-size: 16px;
    border: none;
    padding: 10px 10px;
    cursor: pointer;
    width: fit-content;
    border-bottom: 3px solid transparent; // Indicate active tab
    ${({ active }) => active && `
        color: #064274;
        border-color: #064274;
        font-weight: 600;
    `}
`;

export const Title = styled.div`
    color: #2C2C2C;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0px 20px;

    @media screen and (max-width: 800px) {
    text-align: center;
    font-size: 20px;
    }
`;

export const BorderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    margin: 0px 20px;
    border: 1px solid #ddd;
    border-radius: 7px;

    @media screen and (max-width: 800px) {
        padding: 20px;
        margin: 0px 15px;
    }
`;

export const ItemContainer = styled.div`
    display: flex;
    // gap: 10px;
    flex-direction: column;
`;


export const Heading = styled.div` // Changed to h3 for semantic HTML, adjust as necessary
    border-bottom: 1px solid #ddd;
    padding: 5px 0px;
    color: #064274;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const DocumentRow = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f1f1f1;
    padding: 10px 20px;
    align-items: baseline;

    &:last-child{
        border-bottom: unset;
    }

    &.hover {
        &:hover {
            background: aliceblue;
            cursor: pointer;
        }
    }
`;

export const DocumentType = styled.div`
    color: #2c2c2c;
    font-weight: 800;
    font-size: 14px;
    // color: ${props => props.documentStatus ? '#a4a4a4':'#FFA500'};

    &.title {
        font-weight: 500;
        color: var(--Greyish-Black, #323232);
    }

    &.total-title {
        font-weight: 600;
        color: #064274;
    }
`;

export const Button = styled.button`
    font-size: 10px;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-weight: 600;
    color: white;
    width: 150px;
`;

export const PreviewContainer = styled.div`
    display: flex;
    // gap: 20px 20px;
    // border-top: 1px solid #ddd;
    padding: 5px 20px 5px 20px;
    align-items: baseline;
    justify-content: space-between;
    background: aliceblue;
`;

export const StatusBadge = styled.div`

    ${({ status }) => status && css`
        background-color: ${statusColors[status].background};
        color: ${statusColors[status].color};
    `}
    border-radius: 15px;
    padding: 2px 10px;
    font-size: 11px;
    font-weight: 600;
    user-select: none;
    width: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
`;

const statusColors = {
    "Completed": { background: '#E3FFE6', color: '#1CCB00' },
    "Pending": {background: '#FFD1D1', color: '#D8000C' },
};

export const FlexDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const DocumentButton = styled(Button)`
    background: #fff;
    color: #323232;
    width: fit-content;
`;