import React, { useState, useEffect, useRef, useContext } from 'react';
// import { ApiContext } from '../../context/apiContext';
import {
    InputDiv, FlexDiv, Input, SearchBox, SearchItem
} from './dropdown-search.styles'

const DropdownSearch = ({ handleChange, label, isRequired, error, defaultValue = ''}) => {
    const [searchTerm, setSearchTerm] = useState(defaultValue);
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    // const api = useContext(ApiContext)

    useEffect(() => {
        setSearchTerm(defaultValue || "");
    }, [defaultValue]);

    // Fetch data based on searchTerm
    useEffect(() => {
        if (searchTerm && searchTerm.trim()) {
            setIsLoading(true);
            // Simulate an API call
            const fetchData = async () => {
                const response = await fetch(`/api/search/broker?query=${searchTerm}`);
                const data = await response.json();
                setIsLoading(false);
                // console.log(data)
                setResults(data.brokers.length > 0 ? data.brokers : [searchTerm]); // Include searchTerm as a fallback
            };
            fetchData();
            // setTimeout(() => {
            //     const mockResults = ['Keller Williams', 'BSW', 'Burton Realty', 'Evo Realty', 'Block Realty'];
            //     setResults(mockResults.filter(item => item.toLowerCase().includes(searchTerm.toLowerCase())).length > 0
            //          ? mockResults.filter(item => item.toLowerCase().includes(searchTerm.toLowerCase())) : [searchTerm]);
            //     setIsLoading(false);
            // }, 500); // Simulated fetch delay
        } else {
            setResults([]);
        }
    }, [searchTerm]);

    const handleSelect = (selectedItem, event) => {
        event.stopPropagation();
        setSearchTerm(selectedItem); // Update searchTerm to reflect the selected item
        handleChange(selectedItem); // Update parent component
        setResults([]);
        setTimeout(() => setIsDropdownVisible(false), 0);
    };

    const handleSearch = (e) => {
        if(e.target.value === ''){
            setSearchTerm(e.target.value);
            handleChange(null);
            setIsDropdownVisible(false);
        }else{
            setSearchTerm(e.target.value);
            setIsDropdownVisible(true);
        }
    }

    const wrapperRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsDropdownVisible(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <InputDiv ref={wrapperRef} style={{position:'relative'}}>
            <FlexDiv>
                { label && <div>{label}{isRequired && <span style={{color:'red'}}>*</span>}</div>}
                {error && <span style={{color: 'red'}}>{error}</span>}
            </FlexDiv>
            <Input
                type="text"
                placeholder={`Search for ${label}`}
                value={searchTerm}
                onChange={handleSearch}
            />
            {
                isDropdownVisible && 
                <SearchBox>
                    {
                        isLoading?
                        <div>Loading...</div>
                        :
                        <div>
                        {
                            results.map((item, index) => (
                                <SearchItem key={index} onClick={(event) => handleSelect(item, event)}>
                                    {item}
                                </SearchItem>
                            ))
                        }
                        </div>
                    }    
                </SearchBox>
            }
        </InputDiv>
    );
};

export default DropdownSearch;
