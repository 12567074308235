import styled from "styled-components";

// Styled components for the filter
export const FilterWrapper = styled.div`
    position: relative;
    display: inline-block;
    margin-top: 5px;
`;

export const FilterButton = styled.div`
    display: flex;
    gap: 5px;
    width: -webkit-fill-available;
    cursor: pointer;
    color: ${props => props.selected && '#064274'};
    font-weight: ${props => props.selected ? '700':'500'};
`;

export const DropdownContent = styled.div`
    display: ${(props) => (props.show ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    position: absolute; 
    background-color: white;
    border: 1px solid #ccc;
    height: fit-content;
    max-height: 400px;
    overflow-y: auto;
    z-index: 1000; /* Set a high z-index to stay above other elements */
    top: 100%;
    left: 0;
    margin-top: 5px; /* Adjust as necessary */
    min-width: 150px;
    max-width: 250px;
    padding: 5px;
    border-radius: 10px;
`;

export const SearchInput = styled.input`
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    margin-bottom: 8px;
    border-radius: 5px;
`;

export const OptionLabel = styled.label`
    display: flex;
    align-items: center;
    
    padding: 4px 8px;
    &:hover {
      background-color: #f9f9f9;
    }
`;

export const SelectionBox = styled.div`
    // border: 1px solid #ccc;
    // border-radius: 10px;
    height: -webkit-fill-available;
    overflow: auto;
`;

export const FlexDiv = styled.div`
    display: flex;
    gap: 10px;
    justify-content: space-between;
`;

export const SubmitButton = styled.button`
    background-color: #064274    ;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    font-size: 12px;

    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }
`;

export const ResetButton = styled(SubmitButton)`
    background-color: #F7F7F7;
    color: #323232;
    font-weight: 400;
    // cursor: pointer;
    
    &:hover{ 
        background-color: #dedede80;
        transition: background-color 0.2s;
    }
`;