import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
`;

export const ModalContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  max-width: 450px;
  width: 100%;
  padding: 20px;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

export const ModalTitle = styled.div`
    color: var(--Greyish-Black, #323232);
    leading-trim: both;
    text-edge: cap;

    /* Title/Extra Small */
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

export const ModalBody = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #eee;
  padding-top: 10px;
  margin-top: 20px;
  gap: 10px;
`;

export const FormGroup = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;

`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
`;


export const ConfirmButton = styled.button`
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  border: unset;
  border-radius: 5px;
  background:  #064274;
  color: #FFFFFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  width: 120px;
  
  &:hover {
      background-color: #002456;
      transition: background-color 0.2s;
  }

  @media screen and (max-width: 600px){
      width: -webkit-fill-available;
      font-size: 14px;
  }
`;

export const CancelButton = styled(ConfirmButton)`
  background: #E45A5C;
  color: #FFFFFF;
  &:hover {
      background-color: #B5373A; 
  }
`;

export const NameText = styled.div`
  width: 150px;
  /* Body/Large */
  font-family: Avenir;
  font-style: normal;
  line-height: normal;
  color: #064274;
  font-weight: 500;
  font-size: 14px;
`;