import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import FormInput from '../form-input/form-input'
import { toast } from 'react-toastify'
import { ApiContext } from "../../context/apiContext";
import ImageModal from "../image-modals/image-modals";
import { 
    Values,
    Keys,
    GridContainer,
    Heading,
    AttachmentBox,
    Container,
    Section,
    LinkDiv,
    TitleDiv,
    FlexDiv,
    CloseButton,
    RadioGroup,
    RadioElement,
    RadioButton,
    RadioLabel,
    ConfirmButton,
    InputTitle,
    UploadContainer,
    UploadInstruction,
    HiddenFileInput,
    FileUploadLabel,
    RoundDiv,
    RemoveButton
} from "./payment-box.styles";
import { ReactComponent as Document } from '../../assets/document-upload.svg'



const PaymentBox = ({focusPayment, onClose, dealId, updateDeal, showPayment = false, focusDeal = {}}) => {
    const api = useContext(ApiContext);
    const {rentType, rentValue} = focusPayment;
    const [isFocus, setFocus] = useState('Deposit Link');
    const [errors, setErrors] = useState({});
    const [previewUrl, setPreviewUrl] = useState('')
    const [checkDetails, setCheckDetails] = useState({
        checkNo: '',
        attachment: null
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImageUrl, setModalImageUrl] = useState('');

    useEffect(()=> {
        setErrors({})
        setPreviewUrl(null);
        setCheckDetails({
            checkNo: '',
            attachment: null
        })
    },[focusPayment])

    const handleClose = (event) => {
        event.stopPropagation();
        onClose();
    }

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setCheckDetails(prev => ({
                ...prev,
                attachment: event.target.files[0]
            })
        )
        if (file && file.type.substr(0, 5) === 'image') {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const onRemove = () => {
        setPreviewUrl(null)
        setCheckDetails(prev => ({...prev, attachment: null}))
    }

    const validateInputs = () => {
        let newErrors = {}
        if (!checkDetails.checkNo) newErrors.checkNo = "Check No is required";
        if (!checkDetails.attachment) newErrors.attachment = "Check Image is required";
        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    }

    const updateCheck = async (event) => {
        event.stopPropagation();
        if(validateInputs()){
            const formData = new FormData();
            formData.append('dealId', dealId);
            formData.append('rentType', rentType)
            formData.append('checkNo', checkDetails.checkNo);
            formData.append('attachment', checkDetails.attachment);
    
            try {
                const data = await api.put('/api/deal/update_deposit/', formData);
                updateDeal(data, rentType);
                toast.success('Payment Updated')
                handleClose(event);
            } catch (error) {
                toast.error('Error during the fetch operation:', error.message);
            }
        }
    }
    
    const updateLink = async (event) => {
        event.stopPropagation();
        const payload = {
            dealId: dealId,
            paymentType: rentType,
            amount: rentValue?.amount,
            dueDate: rentValue?.dueDate
        }
        try {
            const data = await api.put('/api/deal/new-deposit-link', payload);
            updateDeal(data, rentType);
            toast.success('New link has been shared with the agent')
            handleClose(event);
        }catch{
            toast.error('Something didnt work like it was supposed to');
        }
    }

    const openModal = (imageUrl) => {
        setModalImageUrl(imageUrl);
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
        setModalImageUrl('');
    }

    return (
        <>
        {
            rentValue?.status === 'Completed' ?
            <Container>
                <TitleDiv>
                    <Heading>Payment Details</Heading>
                    <FlexDiv>
                        <span style={{fontSize:"14px"}}>Close</span>
                        <CloseButton onClick={handleClose}>X</CloseButton>
                    </FlexDiv>
                </TitleDiv>
                <Section>
                    <GridContainer> 
                        {
                            showPayment &&
                            <>
                                <Keys>Property</Keys>
                                <Values>{focusDeal?.property?.address}</Values>
                                <Keys>Payment Type</Keys>
                                <Values>{rentType}</Values>
                            </>
                        }
                        <Keys>Paid On</Keys>
                        <Values>{moment(new Date(rentValue?.paidOn)).format('MMM DD, YYYY')}</Values>
                        {
                            rentValue?.method === "Cheque"?
                            <>
                                <Keys>Check Number</Keys>
                                <Values>{rentValue?.checkNo}</Values>
                            </>
                            :
                            <>
                                <Keys>Transaction Number</Keys>
                                <Values>{rentValue?.onlineLinkNo}</Values>
                            </>
                        }
                        <Keys>Method</Keys>
                        <Values>Via {rentValue?.method}</Values>
                    </GridContainer>
                </Section>                    
                {
                    rentValue?.method === "Cheque" ?
                    <Section>
                        <Heading>Check Image</Heading>
                        <AttachmentBox onClick={() => openModal(rentValue?.value)}>
                            <img src = {rentValue?.value}/>
                        </AttachmentBox>
                    </Section>
                    :
                    <Section>
                        <Heading>Transaction Reference</Heading>
                        <LinkDiv>{rentValue?.value}</LinkDiv>
                    </Section>
                }
            </Container>
            :
            <Container>
                <TitleDiv>
                    <Heading>Update Status</Heading>
                    <FlexDiv>
                        <span style={{fontSize:"14px"}}>Close</span>
                        <CloseButton onClick={handleClose}>X</CloseButton>
                    </FlexDiv>
                </TitleDiv>
                {
                    showPayment &&
                    <GridContainer>
                        <Keys>Property</Keys>
                        <Values>{focusDeal?.property?.address}</Values>
                        <Keys>Payment Type</Keys>
                        <Values>{rentType}</Values>
                    </GridContainer>
                }
                <Section>
                    <Heading>Choose an action</Heading>
                    <RadioGroup>
                    {['Deposit Link','Update Check'].map((action) => (
                        <RadioElement key={action}>
                        <RadioButton
                            type="radio"
                            id={action}
                            name="action"
                            value={action}
                            checked={isFocus === action}
                            onChange={() => setFocus(action)}
                        />
                        <RadioLabel htmlFor={action} isSelected={isFocus === action}>
                            {action}
                        </RadioLabel>
                        </RadioElement>
                    ))}
                    </RadioGroup>
                </Section>
                {
                    isFocus === "Deposit Link" ?
                    <Section>
                        <InputTitle>Current Payment Request Link</InputTitle>
                        <LinkDiv>{rentValue?.linkId}</LinkDiv>
                        <ConfirmButton onClick={updateLink}>Generate & Send New Link</ConfirmButton>
                    </Section>
                    :
                    <Section>
                        <FormInput
                            type = 'text'
                            label = 'Check #'
                            handleChange={(value) => setCheckDetails(prev => ({...prev, checkNo: value}))}
                            isRequired={true}
                            defaultValue = {checkDetails.checkNo}
                            error={errors.checkNo}
                        />
                        <Heading>Upload Check Image</Heading>
                        <UploadContainer>
                            {
                                previewUrl ?
                                <AttachmentBox>
                                    <img src = {previewUrl}/>
                                    <RemoveButton onClick={onRemove}>×</RemoveButton>
                                </AttachmentBox>
                                :
                                <FileUploadLabel htmlFor="file-upload">
                                    <RoundDiv><Document /></RoundDiv>
                                    <HiddenFileInput 
                                        id="file-upload" 
                                        type="file" 
                                        accept="image/*" 
                                        onChange={handleFileUpload} 
                                    />
                                    <UploadInstruction>Click to Upload<br/>(Max. File size: 5 MB)</UploadInstruction>
                                    {
                                        errors.attachment &&
                                        <span>{errors.attachment}</span>
                                    }
                                </FileUploadLabel>
                            }
                        </UploadContainer>
                        <ConfirmButton onClick={updateCheck}>Update Payment</ConfirmButton>
                    </Section>
                }
            </Container>
        }
        {
            isModalOpen &&
            <ImageModal onClose={closeModal}>
                <img src={modalImageUrl} alt="Zoomed Check" />
            </ImageModal>
        }
        </>
    )
}

export default PaymentBox