import styled from 'styled-components';

// Existing styled components...

export const DateRangeInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
`;

export const DateRangeInput = styled.input`
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    &:hover {
        border-color: #064274;
    }
`;

export const FilterWrapper = styled.div`
    position: relative;
//   display: inline-block;
//   margin-top: 5px;
`;

export const FilterButton = styled.div`
    display: flex;
    gap: 5px;
    width: -webkit-fill-available;
    cursor: pointer;
    color: ${props => props.selected && '#064274'};
    font-weight: ${props => props.selected ? '700':'500'};
`;

export const DropdownContent = styled.div`
    display: ${(props) => (props.show ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    width: 150px;
    height: fit-content;
    max-height: 400px;
    position: absolute;
    margin-top: 20px;
    top: 100%;
    left: 0;
    overflow-y: auto;
    z-index: 100;
    padding: 5px;
    border-radius: 10px;
`;


export const FlexDiv = styled.div`
    display: flex;
    gap: 10px;
    justify-content: space-between;
`;

export const SubmitButton = styled.button`
    background-color: #064274    ;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    font-size: 12px;

    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }
`;

export const ResetButton = styled(SubmitButton)`
    background-color: #F7F7F7;
    color: #323232;
    font-weight: 400;
    // cursor: pointer;
    
    &:hover{ 
        background-color: #dedede80;
        transition: background-color 0.2s;
    }
`;

export const DateLabel = styled.span`
    display: block; /* This ensures the label is on a new line above the input */
    font-size: 12px; /* Adjust based on your design */
    color: #333; /* Darker color for better readability */
    margin-bottom: 4px; /* Space between the label and input */
    font-weight: 500; /* Slightly bold for emphasis */
`;