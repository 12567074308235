import React, { useState, useRef, useEffect } from 'react';
import {
    FilterWrapper,
    FilterButton,
    DropdownContent,
    FlexDiv,
    SubmitButton,
    ResetButton,
    DateRangeInputWrapper,
    DateRangeInput,
    DateLabel
} from './date-range-picker.styles';

import { ReactComponent as Filter } from '../../assets/Table-Filter.svg';
import { ReactComponent as FilterSelected } from '../../assets/Table-Filter-Fill.svg';

const DateRangePicker = ({ selectedDateRange, onChange, placeholder }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [startDate, setStartDate] = useState(selectedDateRange?.from || '');
    const [endDate, setEndDate] = useState(selectedDateRange?.to || '');
    const divRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleSubmit = () => {
        let from = startDate || new Date(0).toISOString().split('T')[0]; // Minimum date if only 'to' is selected
        let to = endDate || new Date('9999-12-31').toISOString().split('T')[0]; // Maximum date if only 'from' is selected
        onChange({ from, to });
        // onChange({ from: startDate, to: endDate });
        setIsOpen(false);
    };

    const handleReset = () => {
        setStartDate('');
        setEndDate('');
        onChange({ from: '', to: '' });
        setIsOpen(false);
    };

    return (
        <FilterWrapper ref={divRef}>
            <FilterButton selected={startDate && endDate} onClick={toggleDropdown}>
                {placeholder} {selectedDateRange.from && selectedDateRange.to ? <FilterSelected /> : <Filter />}
            </FilterButton>
            <DropdownContent show={isOpen}>
                <DateRangeInputWrapper>
                    <label>
                        <DateLabel>From</DateLabel>
                        <DateRangeInput
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            placeholder="Start Date"
                        />
                    </label>
                    <label>
                        <DateLabel>To</DateLabel>
                        <DateRangeInput
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            placeholder="End Date"
                        />
                    </label>
                </DateRangeInputWrapper>
                <FlexDiv>
                    <ResetButton onClick={handleReset}>Reset</ResetButton>
                    <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
                </FlexDiv>
            </DropdownContent>
        </FilterWrapper>
    );
};

export default DateRangePicker;
