import React, {useEffect, useState} from "react";
import { formatDate } from "../../utilities/utility";
import {
    SearchBar, PropertyList, CenterDiv, Canvas, Card, ImageContainer, PropertyDetails, PropertyHeader, PropertyPrice,
    PropertyImage, PropertyTitle, FlexDiv, DetailsRow, ButtonRow, ButtonsRow, Button, Title, StickyDiv, RoundDiv, TitleDiv
} from './change-application.styles'

import { ReactComponent as Bed } from '../../assets/bed.svg';
import { ReactComponent as Bath } from '../../assets/bath.svg';
import { ReactComponent as Area } from '../../assets/area.svg';
import { ReactComponent as Apps } from '../../assets/apps.svg';
import { ReactComponent as Truck } from '../../assets/bi_truck.svg';
import { ReactComponent as Wallet } from '../../assets/Wallet.svg';
import { FaSearch } from 'react-icons/fa'; // Make sure to install react-icons
import NewAppModality from "../new-application-modality/new-application-modality";

const ChangeApplication = ({applicationData, setOtherApplicationState, otherApplicationState}) => {
    const [propertyList, setPropertyList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newProperty, setNewProperty] = useState();
    const [searchTerm, setSearchTerm] = useState('');

    const showDate = (movein) => {
        // console.log(movein)
        const parts = movein.split("-");
        const isoFormattedString = `${parts[2]}-${parts[0]}-${parts[1]}`; // Reformat to "YYYY-MM-DD"
        const dateObj = new Date(isoFormattedString);
    
        const { formattedDate } = formatDate(dateObj);
        return formattedDate
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Extract the subdomain from the current URL
                const { hostname } = window.location;
                const subdomain = hostname.split('.')[0];

                let response = await fetch(`/api/listing/all?company=${subdomain}`);
                if (response.ok) {
                    let data = await response.json();
                    setPropertyList(data);
                } else {
                    // Handle errors, e.g., response status not OK
                    console.error("Failed to fetch properties:", response.status);
                }
            } catch (error) {
                // Handle network errors or other fetch issues
                console.error("Failed to fetch properties:", error);
            }
        };
        fetchData();
    }, [])

    // Function to handle search input changes
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    }

    // Filtered property list based on search term
    const filteredProperties = propertyList.filter(property =>
        property.address.toLowerCase().includes(searchTerm)
        // Add other property fields you want to search by
    );

    const submitClick = (property) => {
        setNewProperty(property);
        setIsModalOpen(true);
    }


    return (
        <Canvas>
            <StickyDiv>
                <TitleDiv>
                    <RoundDiv onClick={()=>setOtherApplicationState()}>&#x2190;</RoundDiv>
                    <Title>{otherApplicationState==="Switch"? "Switching application":"Creating a new application"}</Title>
                </TitleDiv>
                <SearchBar>
                    <FaSearch color="#aaa" /> {/* Adjust color as needed */}
                    <input type="text" placeholder="Search" onChange={handleSearchChange}/>
                </SearchBar>
            </StickyDiv>
            {
                filteredProperties.length > 0 ?
                <PropertyList>
                    {
                        filteredProperties.map(property => (
                            <Card>
                                <PropertyDetails>
                                    <ImageContainer>
                                        <PropertyImage src={property.imageUrl} alt='Property' />
                                    </ImageContainer>
                                    <PropertyHeader>
                                        <PropertyTitle>{property.address}</PropertyTitle>
                                        <FlexDiv>
                                            <PropertyPrice><Wallet stroke='#525252'/>${property.price}</PropertyPrice>
                                            <PropertyPrice><Truck fill='#525252'/> {showDate(property.moveIn)}</PropertyPrice>
                                            {
                                                property.numApps > 0 &&
                                                <PropertyPrice style={{ color: '#E45A5C' }}><Apps stroke="#E45A5C"/>{property.numApps} Apps received</PropertyPrice>
                                            }
                                        </FlexDiv>
                                    </PropertyHeader>
                                </PropertyDetails>
                                <ButtonsRow>
                                    <DetailsRow>
                                        <PropertyPrice><Bed stroke='#7e7e7e'/> {property.bedrooms}</PropertyPrice>
                                        <PropertyPrice><Bath /> {property.bathrooms}</PropertyPrice>
                                        {
                                            property.area &&
                                            <PropertyPrice><Area fill='#7e7e7e'/> {property.area} ft²</PropertyPrice>
                                        }
                                    </DetailsRow>
                                    <ButtonRow>
                                        <Button onClick={() => submitClick(property)}>Apply</Button>
                                    </ButtonRow>
                                </ButtonsRow>
                            </Card>
                        ))
                    }    
                </PropertyList>
                :
                <CenterDiv><span>No Properties Found</span></CenterDiv>
            } 
            {
                isModalOpen &&
                <NewAppModality
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    newProperty={newProperty}
                    applicationData={applicationData}
                    otherApplicationState={otherApplicationState}
                    setOtherApplicationState = {setOtherApplicationState}
                />
            }
            
        </Canvas>
    )
}

export default ChangeApplication