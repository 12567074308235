import styled from 'styled-components';

// Table Container Wrapper
export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

// Table Row
export const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  border-bottom: 1px solid #ddd;
  background-color: ${({ header }) => (header ? '#f7f7f7' : 'white')};
  font-weight: ${({ header }) => (header ? '600' : '400')};
  &:last-child {
    border-bottom: none;
  }
`;

// Table Cell
export const TableCell = styled.div`
//   flex: 1;
  text-align: left;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:first-child {
    flex:1.5;
    @media only screen and (max-width: 1000px){
        flex: 1;
    }
  }

  &:nth-child(2){
    flex:2.5;
    @media only screen and (max-width: 1100px){
        display: none;
    }
  }

  &:nth-child(3){
    flex: 1.5;
    @media only screen and (max-width: 1200px){
        display: none;
    }
  }


  &:nth-child(4){
    width: 60px;
  }

`;

// Edit Button
export const OpenButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
//   margin-right: 5px;
  font-size: 12px;
  transition: background-color 0.3s;
  &:hover {
    background-color: #0056b3;
  }
`;

// Delete Button
export const DeleteButton = styled(OpenButton)`
  background-color: #dc3545;
  &:hover {
    background-color: #c82333;
  }
`;
