import styled from "styled-components";

// Theme variables (optional, for consistency and ease of maintenance)
const theme = {
    colors: {
        darkGray: "#2C2C2C",
        lightGray: "#A4A4A4",
        primaryBlue: "#064274",
        hoverBlue: "#002456",
        borderGray: "#ccc",
    },
    spacing: {
        sm: "10px",
        md: "12px",
    },
    borderRadius: "7px",
};

export const Section = styled.section`
    display: flex;
    flex-direction: column;
    gap: 15px;
    @media (max-width: 800px) {
        gap: 10px;
    }
`;

export const SectionTitle = styled.div` // Changed to h2 for semantic HTML, adjust as necessary
    color: ${theme.colors.darkGray};
    font-size: 18px;
    font-weight: 600;
`;

export const NextButton = styled.button`
    border: none;
    border-radius: ${theme.borderRadius};
    padding: ${theme.spacing.md};
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    background-color: ${theme.colors.primaryBlue};
    color: white;
    transition: background-color 0.2s;
    
    &:hover { 
        background-color: ${theme.colors.hoverBlue};
    }
`;


export const Label = styled.label`
    color: ${theme.colors.lightGray};
    font-size: 14px;
    font-weight: 400;
`;

export const Currency = styled.div`
    font-size: 10px;
    color: ${theme.colors.darkGray};
`;

export const DateFilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const Heading = styled.div` // Changed to h3 for semantic HTML, adjust as necessary
    color: ${theme.colors.darkGray};
    font-size: 16px;
    font-weight: 500;
`;

export const DropdownDiv = styled.div`
    color: #2C2C2C;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 5px;
    min-width: 250px;
    // max-width: 450px;
    width: -webkit-fill-available;
    flex-basis: 250px;
    flex: 1;
`;


export const Dropdown = styled.select`
    display: flex;
    padding: var(--12, 12px);
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 7px;
    border: 1px solid var(--F1, #F1F1F1);
    color: ${props => props.value === null && 'grey'}
`;


export const AddButton = styled(NextButton)`
    // max-width: 350px;
    background-color: #E45A5C;
    margin: auto;
    &:hover{ 
        background-color: #B5373A; 
        transition: background-color 0.2s;
    }
`;

export const PreviousButton = styled(NextButton)`
    background-color: #E45A5C;
    &:hover{ 
        background-color: #B5373A; 
        transition: background-color 0.2s;
    }
`;

export const ApplicantContainer = styled.div`
    padding: 20px;
    border-radius: 7px;
    border: 1px solid #ddd;
    gap: 15px;
    display: flex;
    flex-direction: column;
    // margin-bottom: 10px; // Adjust based on your layout needs
`;

export const ApplicantDetailGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr 1fr 1fr);
    gap: 10px;
`;

export const RadioButtonLabel = styled(Label)`
    display: flex;
    align-items: center;
    gap: 5px;
    color: #2c2c2c;
`;

export const WrapDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

export const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const GapDiv = styled.div`
    display: flex;
    gap: 10px;
`;

export const InfoBox = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 0 0px 16px rgba(0, 0, 0, 0.3);
    z-index: 1000;
`;


export const AttachmentBox = styled.div`
  display: flex;
  padding: 10px;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
//   gap: 5px;
//   cursor: pointer;
  align-self: stretch;
  border: 1px solid var(--F1, #F1F1F1);
  border-radius: 7px;
`;

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  width: 100%;
`;

export const PDFIconWrapper = styled.div`
  background: var(--White, #FFF);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
`;

export const FileName = styled.span`
  font-size: 14px;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`;


export const FileUploadLabel = styled.label`
  border: 1px dashed var(--Blue-100, #064274);
  background: #F0F8FF;
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  justify-content: center;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  transition: border-color 0.3s;
  border-radius: 10px;
  span {
    color: red;
    font-weight: 500;
  }
`;

export const HiddenFileInput = styled.input`
  display: none;
`;

export const UploadInstruction = styled.p`
    text-align: center;
    color: #747474;
    font-size: 14px;
    margin-top: 10px;
`;

export const RoundDiv = styled.div`
    border-radius: 50px;
    background: var(--White, #FFF);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
`;

